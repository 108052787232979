export const toCurrency = (value, currencyType) => {
  return Number(value).toLocaleString('pt-br', {
    style: 'currency',
    currency: currencyType ?? 'BRL',
    maximumFractionDigits: 2,
  });
};

export const fromCurrencyToNumber = (value) => {
  let amount = value.split(/(\s+)/)[2];
  amount = amount.replace(/\./g, '').replace(/,/g, '.');
  return parseFloat(amount);
};

export const handleSetCurrency = (value, currencyType) => {
  if (typeof value === 'number') {
    return toCurrency(value, currencyType);
  }
  return value;
};

export const getDiscountPrice = (value, discount, type) => {
  let valueDiscount = discount;
  if (discount > 0) {
    if (type === 'percentage') {
      valueDiscount = value * (discount / 100);
    }
  }
  return valueDiscount;
};

const convertNegativeValue = (value, currency, isPercentage) => {
  const divisor = value <= -1.0e9 ? 1000000000 : value <= -1.0e6 ? 1000000 : 1;
  return `${isPercentage ? '' : 'R$ '}${parseFloat(
    value / divisor,
  ).toLocaleString('pt-br', {
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })} ${value <= -1.0e9 ? 'bi' : value <= -1.0e6 ? 'mi' : ''}`;
};

export const convertValue = (
  value,
  valueType = '',
  hasPostFix = true,
  style = 'currency',
  currency = 'BRL',
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
  isPercentage = false,
) => {
  if (value >= 1.0e9) {
    return `${parseFloat(value / 1000000000).toLocaleString('pt-br', {
      style,
      currency,
      maximumFractionDigits,
      minimumFractionDigits,
    })} ${hasPostFix ? 'bi' : ''}`;
  } else if (valueType === 'million' || value >= 1.0e6) {
    return `${parseFloat(value / 1000000).toLocaleString('pt-br', {
      style,
      currency,
      maximumFractionDigits,
      minimumFractionDigits,
    })} ${hasPostFix ? 'mi' : ''}`;
  } else if (value < 0) {
    return convertNegativeValue(value, currency, isPercentage);
  } else {
    return parseFloat(value).toLocaleString('pt-br', {
      style,
      currency,
      maximumFractionDigits,
      minimumFractionDigits,
    });
  }
};

export const convertNumber = (
  value,
  maximumFractionDigits = 3,
  minimumFractionDigits = 0,
) =>
  parseFloat(value).toLocaleString('pt-BR', {
    maximumFractionDigits,
    minimumFractionDigits,
  });

export const calculateRadarValue = (value, divisor = 1000000, digits = 1) => {
  return +(value / divisor).toFixed(digits);
};

export const calculatePercentageValue = (
  sliceValue,
  totalValue,
  digits = 1,
) => {
  return +((sliceValue / totalValue) * 100).toFixed(digits);
};

export const formatRadarValue = (value, digits = 1) => {
  return value.toLocaleString('pt-br', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  });
};

export const formatRadarText = (text) => {
  const maskNumber = (value) => {
    const parsedValue = value.replace(/\./g, '').replace(',', '.');
    const valueNumber = Number(parsedValue);

    if (valueNumber >= 1000000000) {
      return `${(valueNumber / 1000000000).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })} bi`;
    }
    if (valueNumber >= 1000000) {
      return `${(valueNumber / 1000000).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })} mi`;
    }

    return valueNumber;
  };

  return text.replace(/(\d{1,3}\.){1,}\d{3}(,?\d{1,2})/g, maskNumber);
};

export const formatBytes = (bytes, isKB = false) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i + isKB]
  );
};
