import { Box, Divider, Link, Paper, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Media } from 'src/types/strapi';

import breakDefault from '../../assets/imgs/break-default.svg';
import { getSmallestImageUrl } from '../../utils/image';

export default function CardServiceProviderDivider({
  serviceProvider,
  logo,
  name,
  startDate,
  slug,
  hashId,
}: {
  serviceProvider: string;
  logo?: Media;
  name?: string;
  startDate?: string;
  slug: string;
  hashId: string;
}) {
  return (
    <>
      {serviceProvider === 'Securitizadora' || !hashId ? (
        <Box>
          <Paper
            square
            sx={(theme) => ({
              '&:hover': {
                backgroundColor: theme.palette.grey[50],
              },
            })}>
            <Box p={2}>
              <Box display="flex" alignItems="center">
                <Box
                  component={'img'}
                  src={breakDefault}
                  alt="Sem logo"
                  sx={{
                    width: 80,
                    height: '100%',
                    backgroundSize: 'contain',
                  }}
                />
                <Box p={3} overflow={'hidden'}>
                  <Tooltip title={name} placement="top" arrow>
                    <Typography
                      component="div"
                      variant="subtitle2"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      noWrap={true}
                      sx={(theme) => ({
                        fontWeight: theme.typography.fontWeightBold,
                        [theme.breakpoints.up('lg')]: {
                          width: 220,
                        },
                        [theme.breakpoints.between('md', 'sm')]: {
                          width: 200,
                        },
                        [theme.breakpoints.down('xs')]: {
                          width: 180,
                        },
                      })}>
                      {name}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
              <Divider
                sx={(theme) => ({
                  height: 2,
                  margin: theme.spacing(2, 0),
                  backgroundColor: theme.palette.secondary.main,
                  opacity: 0.5,
                })}
              />
              <Box display="flex" justifyContent="flex-end">
                {startDate ? (
                  <Typography variant="caption">{`Mandato iniciado em: ${startDate}`}</Typography>
                ) : (
                  <Typography style={{ height: 19 }} />
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      ) : (
        <Link
          component={RouterLink}
          underline="none"
          to={`/instituicao/${slug}/${hashId}`}>
          <Paper
            square
            sx={(theme) => ({
              '&:hover': {
                backgroundColor: theme.palette.grey[50],
              },
            })}>
            <Box p={2}>
              <Box display="flex" alignItems="center">
                {logo ? (
                  <Box
                    component={'img'}
                    src={getSmallestImageUrl(logo)}
                    alt="Logo"
                    sx={{
                      width: 80,
                      height: '100%',
                      backgroundSize: 'contain',
                    }}
                  />
                ) : (
                  <Box
                    component={'img'}
                    src={breakDefault}
                    alt="Sem logo"
                    sx={{
                      width: 80,
                      height: '100%',
                      backgroundSize: 'contain',
                    }}
                  />
                )}
                <Box p={3}>
                  <Typography
                    component="div"
                    variant="subtitle2"
                    sx={(theme) => ({
                      whiteSpace: 'nowrap',
                      fontWeight: theme.typography.fontWeightBold,
                      width: 170,
                      [theme.breakpoints.between('md', 'sm')]: {
                        width: 280,
                      },
                      [theme.breakpoints.up('lg')]: {
                        width: 250,
                      },
                      [theme.breakpoints.down('xs')]: {
                        width: 190,
                      },
                    })}>
                    <Box textOverflow="ellipsis" overflow="hidden">
                      {name}
                    </Box>
                  </Typography>
                </Box>
              </Box>
              <Divider
                sx={(theme) => ({
                  height: 2,
                  margin: theme.spacing(2, 0),
                  backgroundColor: theme.palette.secondary.main,
                  opacity: 0.5,
                })}
              />
              <Box display="flex" justifyContent="flex-end">
                {startDate ? (
                  <Typography variant="caption">{`Mandato iniciado em: ${startDate}`}</Typography>
                ) : (
                  <Typography style={{ height: 19 }} />
                )}
              </Box>
            </Box>
          </Paper>
        </Link>
      )}
    </>
  );
}
