import { useEffect, useRef, useState } from 'react';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { OperationResume } from 'src/types/operations/cr';

import { If } from '../../../../../components/If';
import { PanelLogin } from '../../../../../components/PanelLogin';
import PanelTitles from '../../../../../components/PanelTitles';
import { useAuthContext } from '../../../../../context/AuthContextProvider';

type OperationCRAResumeTitlesProps = Pick<OperationResume, 'titles'> & {
  isLoading: boolean;
};

function PaperTitles({
  typeTitle,
  data,
  isExtraSmall,
}: {
  typeTitle: string;
  data?: {
    class?: string;
    series: string;
    emissionDate: string;
    cetipCode: string;
    amountIssued: number;
    term: number;
    remuneration: number;
  }[];
  isExtraSmall: boolean;
}) {
  const sliderRef = useRef<HTMLDivElement | null>();
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const nextRef = useRef<HTMLButtonElement | null>(null);
  const [count, setCount] = useState(1);
  const [percent, setPercent] = useState(0);
  const [slider, setSlider] = useState<{ width: number; length: number }>(
    {} as { width: number; length: number },
  );
  const dividend = isExtraSmall ? 80 : 100;

  function handleNextClick() {
    if (count < slider.length) {
      const percentage = dividend / slider.length;
      const translate = `translate(-${percentage * count}%)`;
      setPercent(percentage * count);
      setCount(count + 1);
      if (sliderRef.current) {
        sliderRef.current.style.transform = translate;
      }
    }
  }

  function handlePrevClick() {
    if (count <= slider.length) {
      const percentage = dividend / slider.length;
      const translate = `translate(-${percent - percentage}%)`;
      setPercent(percent - percentage);
      setCount(count - 1);
      if (sliderRef.current) {
        sliderRef.current.style.transform = translate;
      }
    }
  }

  useEffect(() => {
    const width = (data?.length ?? 0) * 100;
    const length = data?.length ?? 0;

    setSlider({ width, length });
  }, [data?.length]);

  return (
    <Paper
      square
      sx={(theme) => ({
        position: 'relative',
        height: 360,
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
          height: 400,
        },
      })}>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.secondary.dark,
          width: 200,
          height: 360,
          position: 'absolute',
          padding: theme.spacing(3),
          [theme.breakpoints.down('xs')]: {
            height: 400,
          },
        })}>
        <Typography
          color="secondary"
          sx={(theme) => ({
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.main,
          })}
          variant="body1">
          &mdash;{typeTitle}
        </Typography>
      </Box>
      <Box
        sx={(theme) => ({
          margin: '50px 24px',
          width: 369,
          height: 265,
          position: 'absolute',
          [theme.breakpoints.down('xs')]: {
            margin: '70px 15px',
          },
        })}>
        <Box display="flex" width="100%" height="100%">
          <Box
            display="flex"
            height="100%"
            width={slider.width + '%'}
            flexShrink={0}
            ref={sliderRef}
            sx={{ transition: 'all 0.5s' }}>
            {data?.map(
              ({
                series,
                emissionDate,
                cetipCode,
                amountIssued,
                term,
                remuneration,
              }) => (
                <PanelTitles
                  key={`${cetipCode}${series}`}
                  series={series}
                  emissionDate={emissionDate}
                  cetipCode={cetipCode}
                  amountIssued={amountIssued}
                  term={term}
                  remuneration={remuneration}
                />
              ),
            )}
          </Box>
        </Box>
      </Box>
      <Box
        right={0}
        bottom={0}
        display="flex"
        justifyContent="flex-end"
        position="absolute"
        p={1}>
        <Button
          ref={prevRef}
          onClick={handlePrevClick}
          color="secondary"
          sx={{ border: '1px solid', height: 30, minWidth: 30 }}
          disabled={count === 1 ? true : false}>
          <KeyboardArrowLeft titleAccess="seta para esquerda" />
        </Button>
        <Button
          ref={nextRef}
          onClick={handleNextClick}
          color="secondary"
          sx={{ border: '1px solid', height: 30, minWidth: 30 }}
          disabled={count === data?.length ? true : false}>
          <KeyboardArrowRight />
        </Button>
      </Box>
    </Paper>
  );
}

export default function OperationCRResumeTitles({
  titles,
  isLoading,
}: OperationCRAResumeTitlesProps) {
  const { auth } = useAuthContext();
  const isExtraSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );

  return (
    <Box p={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(20),
          })}>
          &mdash;Títulos
        </Typography>
      </Box>
      <Divider
        sx={(theme) => ({
          height: 2,
          backgroundColor: theme.palette.common.black,
        })}
      />
      <If
        condition={
          !auth?.user?.subscription?.plan?.permissions[
            'operacoes_cr_resumo_titulos'
          ]
        }>
        <Box py={1}>
          <PanelLogin message={null} small={null} hasLink={null} />
        </Box>
      </If>
      <If
        condition={
          auth?.user?.subscription?.plan?.permissions[
            'operacoes_cr_resumo_titulos'
          ]
        }>
        {isLoading
          ? null
          : titles?.seniors.length > 0 && (
              <PaperTitles
                typeTitle="Seniores"
                data={titles?.seniors as any}
                isExtraSmall={isExtraSmall}
              />
            )}
        {isLoading
          ? null
          : titles?.mezzanines.length > 0 && (
              <PaperTitles
                typeTitle="Mezaninos"
                data={titles?.mezzanines}
                isExtraSmall={isExtraSmall}
              />
            )}
        {isLoading
          ? null
          : titles?.subordinates.length > 0 && (
              <PaperTitles
                typeTitle="Subordinadas"
                data={titles?.subordinates as any}
                isExtraSmall={isExtraSmall}
              />
            )}
        {isLoading
          ? null
          : titles?.uniques.length > 0 && (
              <PaperTitles
                typeTitle="Únicos"
                data={titles?.uniques}
                isExtraSmall={isExtraSmall}
              />
            )}
      </If>
    </Box>
  );
}
