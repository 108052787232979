import { useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import { useQuery } from 'react-query';
import { UnderlyingDebtors } from 'src/components/UnderlyingDebtors';
import { getOperationAssetTabData } from 'src/services/operation/operationCr';
import { OperationAsset } from 'src/types/operations/cr';

import { OperationCRAssetAssignors } from './AssetAssignors';
import { OperationCRAssetCharacteristics } from './Characteristics';
import { PanelLogin } from '../../../../components/PanelLogin';
import { useAuthContext } from '../../../../context/AuthContextProvider';

export default function OperationCRAsset({ hashId }: { hashId: string }) {
  const { auth } = useAuthContext();
  const [data, setData] = useState<OperationAsset>({} as OperationAsset);
  const { isLoading, error } = useQuery({
    queryKey: ['operationAsset', hashId],
    queryFn: () => getOperationAssetTabData(hashId),
    onSuccess: (response) => {
      setData(response);
    },
    enabled:
      !!auth?.user?.subscription?.plan?.permissions['operacoes_cr_ativo'],
  });

  if (error) return null;

  return (
    <Box p={2}>
      {!auth?.user?.subscription?.plan?.permissions['operacoes_cr_ativo'] ? (
        <Box p={2}>
          <PanelLogin message={null} small={null} />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <OperationCRAssetCharacteristics
              characteristics={data.characteristics}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <OperationCRAssetAssignors hashId={hashId} market="cr" />
          </Grid>
          <Grid item xs={12}>
            <UnderlyingDebtors hash={hashId} market="cr" />
          </Grid>
          {/* TO-DO: Será implementado após o fim do MVP */}
          {/* <OperationCraAssetWalletPDD
            operationId={hashId}
            operationName={operationName}
          />
          <OperationCRAAssetReceivables
            hash={hashId}
            operationName={operationName}
          /> */}
        </Grid>
      )}
    </Box>
  );
}
