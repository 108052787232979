import dayjs from 'dayjs';
import qs from 'qs';
import { StrapiResponse } from 'src/types/http';
import {
  FidcFundHistory,
  FidcFundOption,
  GetFundsHistoryParams,
  UnderlyingDebtor,
} from 'src/types/operation';

import { api, publicApi } from '../../api/api';

export const getSecondaryFilters = async (hash: string) => {
  const { data } = await api.get(`/getSecondaryFilters?operationId=${hash}`);
  return data;
};

export const filterSecondary = async (values: any) => {
  const { data } = await api.post('/filterSecondary', values);
  return data;
};

export const exportSecondary = async (values: any) => {
  const { data } = await api.post('/filterSecondary/export', values);
  return data;
};

export const countOperations = async ({ market }: { market: string }) => {
  let request;
  const query = qs.stringify(
    {
      market,
    },
    {
      encodeValuesOnly: true,
    },
  );

  if (market.toUpperCase() === 'CR') {
    request = publicApi.get('/ipanema-two/basic-view-operations/count');
  } else {
    request = publicApi.get(`/operations-detailed/count?${query}`);
  }
  const { data } = await request;
  return data;
};

export const findOperationsByName = async (name: string) => {
  const query = qs.stringify(
    {
      sort: ['name:asc'],
      filters: {
        name: {
          $containsi: name,
        },
      },
      populate: ['market'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/operacoes?${query}`);
  return data;
};

export const getOperationRelatedArticles = async ({
  start,
  limit,
  operationId,
}: {
  start: number;
  limit: number;
  operationId: string;
}) => {
  const query = qs.stringify(
    {
      pagination: {
        start,
        limit,
      },
      filters: {
        relatedOperations: {
          hashId: operationId,
        },
        publishedAt: {
          $null: false,
        },
      },
      populate: ['headerImage', 'markets'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await publicApi.get(`/artigos?${query}`);
  return data;
};

export const getOperationForHome = async () => {
  const query = qs.stringify(
    {
      fields: ['name', 'hashId', 'slug'],
      populate: ['market'],
      pagination: {
        page: 1,
        pageSize: 6,
      },
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/operacoes?${query}`);
  return data;
};

export const getColumnsBasicView = async ({ market }: { market: string }) => {
  if (market === 'cr') {
    market = 'cra';
  }
  const query = qs.stringify(
    {
      market,
    },
    {
      encodeValuesOnly: true,
    },
  );

  return api.get(`/operations-detailed/columns?${query}`);
};

export const getColumns = async ({
  market,
  indicator,
}: {
  market: string;
  indicator: string;
}) => {
  const query = qs.stringify(
    {
      market,
      indicator,
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get(`/meta/columns?${query}`);
  return data;
};

export const getAll = async (
  {
    market,
    indicator,
    cnpj,
    permission,
    mode,
    page = 1,
    pageSize = 1000,
    filters,
    sorting,
    populate,
  }: {
    market?: string;
    indicator?: string;
    cnpj?: string;
    permission?: string;
    mode: 'meta' | 'operation';
    page?: number;
    pageSize?: number;
    filters?: Record<string, any>;
    sorting?: Record<string, any>;
    populate?: Record<string, any>;
  },
  setProgress?: (value: number) => void,
) => {
  let request;
  const query = qs.stringify(
    {
      market: mode === 'meta' ? market : undefined,
      indicator,
      permission,
      page,
      pageSize,
      ...(cnpj
        ? {
            parameters: {
              filters: {
                cnpjFundoCota: cnpj,
              },
            },
          }
        : undefined),
      ...(mode === 'operation'
        ? {
            parameters: {
              filters: {
                market,
              },
            },
          }
        : undefined),
      ...(filters ? { filters } : {}),
      ...(sorting ? { sorting } : {}),
      ...(populate ? { populate } : {}),
    },
    {
      encodeValuesOnly: true,
    },
  );
  const config = {
    onDownloadProgress: (progressEvent: any) => {
      if (setProgress) {
        const percentage = Math.round(
          (progressEvent.loaded * 100) /
            (progressEvent.total ??
              progressEvent.srcElement.getResponseHeader('content-length')),
        );
        setProgress(percentage);
      }
    },
  };

  if (market === 'cr') {
    request = api.get(
      `/ipanema-two/operation/basic-view-operations?${query}`,
      config,
    );
  } else {
    request = api.get(`/meta/all?${query}`, config);
  }

  const { data } = await request;

  return {
    data: data.data,
    totalCount: data.meta.pagination.total,
    currentPage: data.meta.pagination.page,
    totalPages: data.meta.pagination.pageCount,
  };
};

export const getFIIWalletCRICRA = async ({ cnpj }: { cnpj: string }) => {
  const query = qs.stringify(
    {
      filters: {
        cnpjFundo: cnpj,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/meta-fii-carteira-cri-cras?${query}`);
  return data;
};

export const getFIIWalletFII = async ({ cnpj }: { cnpj: string }) => {
  const query = qs.stringify(
    {
      filters: {
        cnpjFundo: cnpj,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/meta-fii-carteira-fiis?${query}`);
  return data;
};

export const getFIIWalletFIDC = async ({ cnpj }: { cnpj: string }) => {
  const query = qs.stringify(
    {
      filters: {
        cnpjFundo: cnpj,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/meta-fii-carteira-fidcs?${query}`);
  return data;
};

export const getFIIWalletProperty = async (params: any) => {
  const query = qs.stringify(
    {
      market: params.mode === 'meta' ? params.market : undefined,
      indicator: params.indicator,
      permission: params.permission,
      parameters: {
        filters: {
          cnpjFundo: params.cnpj,
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/meta-fii-ativo-imovels?${query}`);
  return data;
};

export const getCriCraInvestorsPositions = async ({
  operationId,
  permission,
  page = 1,
  pageSize = 1000,
  filters = {},
  sorting = {},
}: {
  operationId: string;
  permission: string;
  page?: number;
  pageSize?: number;
  filters?: Record<string, any>;
  sorting?: Record<string, any>;
}) => {
  const query = qs.stringify(
    {
      permission,
      filters: {
        relatedOperation: {
          operationId,
        },
        ...filters,
      },
      ...sorting,
      populate: {
        investor: {
          fields: ['name', 'slug', 'operationId'],
        },
        relatedOperation: {
          fields: ['class'],
        },
      },
      page,
      pageSize,
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get<{
    data: any[];
    meta: {
      pagination: {
        page: number;
        pageCount: number;
        pageSize: number;
        total: number;
      };
    };
  }>(`/cri-cra-investors-positions?${query}`);
  return data;
};

export const getCRIReceivablesDue = async ({
  operationId,
}: {
  operationId: string;
}) => {
  const query = qs.stringify(
    {
      filters: {
        operation: {
          operationId,
        },
      },
      sort: ['dataReferencia:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/meta-cri-recebiveis-a-vencers?${query}`);
  return data;
};

export const getCRAReceivablesDue = async ({
  operationId,
}: {
  operationId: string;
}) => {
  const query = qs.stringify(
    {
      filters: {
        operation: {
          operationId,
        },
      },
      sort: ['dataReferencia:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const {
    data: { data },
  } = await api.get(`/meta-cra-recebiveis-a-vencers?${query}`);
  return data;
};

export const getOperationsFilters = async ({
  market,
  type,
  indicator,
}: {
  market: string;
  type: string;
  indicator?: string;
}) => {
  const query = qs.stringify(
    {
      filters: {
        market: {
          name: market,
        },
        type,
        indicator,
      },
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/operations-filters-by-user?${query}`);
  return data;
};

export const createOperationFilter = async (values: any) => {
  const { data } = await api.post('/operations-filters', values);
  return data;
};

export const deleteOperationsFilter = async (id: number) => {
  const {
    data: { data },
  } = await api.delete(`/operations-filters/${id}`);
  return data;
};

export const getAutocompleteFidcsFundsOptions = async (): Promise<
  FidcFundOption[]
> => {
  const { data } = await api.get(
    '/meta-fidc-rentabilidade-mensal/fund-autocomplete-options',
  );

  return data;
};

export const getFundClassAndSeries = async ({
  cnpj,
  name,
}: {
  cnpj: string;
  name: string;
}): Promise<{ classe_serie: string; id: number }[]> => {
  const query = qs.stringify(
    {
      fields: ['classe_serie'],
      filters: {
        cnpj_fundo: {
          $eq: cnpj,
        },
        name: {
          $eq: name,
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/meta-fidc-rentabilidade-mensals?${query}`);

  return data.data;
};

export const getFundByName = async (): Promise<FidcFundHistory[]> => {
  const query = qs.stringify(
    {
      name: '',
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(
    `/meta-fidc-rentabilidade-mensal/fund-options?${query}`,
  );

  return data;
};

export const getFundsHistory = async ({
  CNPJs,
  period,
  classSerie,
}: GetFundsHistoryParams): Promise<FidcFundHistory[]> => {
  if (!CNPJs.length) return [];

  const now = dayjs();

  const graterThanDate = period
    ? now.subtract(period, 'months').format('YYYY-MM-DD')
    : null;

  const query = qs.stringify(
    {
      fields: [
        'cnpj_fundo',
        'denominacao_social',
        'rentabilidade_mensal',
        'data_competencia',
        'classe_serie',
      ],
      filters: {
        cnpj_fundo: {
          $in: CNPJs,
        },
        ...(graterThanDate && {
          data_competencia: {
            $gte: graterThanDate,
          },
        }),
        ...(classSerie && {
          classe_serie: {
            $eq: classSerie,
          },
        }),
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get(`/meta-fidc-rentabilidade-mensals?${query}`);

  return data.data;
};

export const getUnderlyingDebtors = async ({
  operationId,
  market,
  page,
  pageSize,
}: {
  operationId: string;
  market: string;
  page: number;
  pageSize: number;
}) => {
  const query = qs.stringify(
    {
      filters: {
        operationId,
      },
      pagination: {
        page,
        pageSize,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  if (market === 'cr') {
    const { data } = await api.get<StrapiResponse<UnderlyingDebtor[]>>(
      `/ipanema-two/operation/get-underlying-debtors?${query}`,
    );

    return data;
  }

  const { data } = await api.get<StrapiResponse<UnderlyingDebtor[]>>(
    `/meta-${market}-cedentes-e-devedoress?${query}`,
  );
  return data;
};
