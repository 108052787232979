import { ChangeEvent, useState } from 'react';

import { Box, Grid, Pagination, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import SkeletonGrid from 'src/components/SkeletonGrid';
import Subtitle from 'src/components/v2/SubTitle';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getUnderlyingDebtors } from 'src/services/operation';
import { UnderlyingDebtor } from 'src/types/operation';

import { UnderlyingDebtorCard } from './UnderlyingDebtorCard';

const PAGE_SIZE = 12;

type OperationUnderlyingDebtorsProps = {
  hash: string;
  market: string;
};

export function UnderlyingDebtors({
  hash,
  market,
}: OperationUnderlyingDebtorsProps) {
  const queryKeyName = `operation-${market.toLowerCase()}-underlying-debtors`;
  const history = useHistory();
  const { auth } = useAuthContext();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [underLyingDebtors, setUnderLyingDebtors] = useState<
    UnderlyingDebtor[]
  >([]);

  const { isLoading } = useQuery({
    queryKey: [queryKeyName, hash, page],
    queryFn: async () =>
      await getUnderlyingDebtors({
        operationId: hash,
        market,
        page,
        pageSize: PAGE_SIZE,
      }),
    onSuccess(data) {
      setUnderLyingDebtors(data.data);
      setPage(data.meta.pagination.page);
      setPageCount(data.meta.pagination.pageCount);
    },
  });

  function handleDebtorClick(debtor: UnderlyingDebtor) {
    if (debtor.operation_id && debtor.operation_slug) {
      history.push(
        `/operacao/${market.toLowerCase()}/${debtor.operation_slug}/${debtor.operation_id}`,
      );

      return;
    }

    if (debtor.institution_id && debtor.institution_slug) {
      history.push(
        `/instituicao/${debtor.institution_slug}/${debtor.institution_id}`,
      );

      return;
    }
  }

  function hasLink(debtor: UnderlyingDebtor) {
    if (market.toUpperCase() === 'CR') {
      return false;
    }
    return (
      !!(debtor.operation_id && debtor.operation_slug) ||
      !!(debtor.institution_id && debtor.institution_slug)
    );
  }

  const handleChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <div>
      <Subtitle title="Devedores" />
      {underLyingDebtors?.length ? (
        <Box py={2}>
          <Grid container spacing={1}>
            {isLoading ? (
              <SkeletonGrid
                width="23.5rem"
                height="7.5rem"
                skeletonNumber={3}
              />
            ) : (
              underLyingDebtors.map((debtor) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  key={`${debtor.id}-${market}-assignor`}
                  onClick={() => handleDebtorClick(debtor)}
                  sx={{
                    cursor: hasLink(debtor) ? 'pointer' : 'default',
                  }}>
                  <UnderlyingDebtorCard
                    key={debtor.id}
                    debtor={debtor}
                    isLoggedIn={auth.isLoggedIn}
                    hasLink={hasLink(debtor)}
                  />
                </Grid>
              ))
            )}
          </Grid>
          <Box pt={3} display="flex" justifyContent="flex-end">
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChange}
              shape="rounded"
            />
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="start" m={1}>
          <Typography>Não foram encontrados devedores</Typography>
        </Box>
      )}
    </div>
  );
}
