import { Link, makeStyles, Paper, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { useAuthContext } from '../../context/AuthContextProvider';
import { If } from '../If';

const useStyles = makeStyles((theme) => ({
  panelLoginPaper: {
    height: 150,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
  small: {
    height: 87,
  },
  panelLoginTitle: {
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  panelLoginLink: {
    color: theme.palette.secondary.main,
  },
}));

export function PanelLogin({ message, small, hasLink }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  return (
    <Paper
      square
      className={`${classes.panelLoginPaper}${
        small ? ` ${classes.small}` : ''
      }`}>
      <Typography
        variant={small ? 'body1' : 'subtitle2'}
        className={classes.panelLoginTitle}>
        <If condition={!auth.isLoggedIn}>
          {message ? <>{message}</> : <>Quer continuar navegando?</>}
        </If>
        <If condition={auth.isLoggedIn}>
          <span>
            {message ? (
              <>{message}</>
            ) : (
              <>
                Seu plano atual não dá acesso a essa informação! Confira nossos
                outros planos
              </>
            )}
          </span>
        </If>
      </Typography>
      <If condition={!auth.isLoggedIn}>
        <Typography>
          <Link
            className={classes.panelLoginLink}
            underline="none"
            component={RouterLink}
            to={'/register'}>
            Cadastre-se
          </Link>
          {' ou Faça seu '}
          <Link
            className={classes.panelLoginLink}
            underline="none"
            component={RouterLink}
            to={'/login'}>
            login
          </Link>
        </Typography>
      </If>
      <If condition={auth.isLoggedIn && hasLink}>
        <Link
          className={classes.panelLoginLink}
          underline="none"
          component={RouterLink}
          to={'/planos'}>
          Clicando aqui
        </Link>
      </If>
    </Paper>
  );
}

PanelLogin.defaultProps = {
  hasLink: true,
  small: false,
  message: '',
};
