import { CircularProgress } from '@mui/material';
import TableLiabilityOperation from 'src/components/TableLiabilityOperation';
import { OperationLiabilityTitle } from 'src/types/operations/cr';

import Subtitle from '../../../../../components/Subtitle';

type OperationCRLiabilityTitleProps = {
  data: OperationLiabilityTitle[];
  isLoading: boolean;
};

export function OperationCRLiabilityTitle({
  data,
  isLoading,
}: OperationCRLiabilityTitleProps) {
  if (isLoading) return <CircularProgress color="secondary" />;

  return (
    <div>
      <Subtitle title="Títulos" />
      <TableLiabilityOperation market="CR" rows={data} />
    </div>
  );
}
