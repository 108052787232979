import { Media } from 'src/types/strapi';

import { publicApi } from '../../api/api';
import { getLargestImageUrl } from '../../utils/image';

export const getChartsData = async () => {
  const { data } = await publicApi.get('metabases');

  return data.data;
};

type BannersResponse = {
  id: number;
  market: 'CRA' | 'CRI' | 'FIDIC' | 'FII';
  banner: Array<{
    id: number;
    sectionId: number;
    url: string;
    banner: {
      url: string;
      formats: {
        small: {
          url: string;
        };
        medium: {
          url: string;
        };
      };
    };
  }>;
};

export const getBanners = async () => {
  const { data } = await publicApi.get(
    'anuario-banners?populate[banner][populate]=*',
  );

  const banners: BannersResponse[] = data.data;

  const parsedResponse = banners.map((b) => ({
    market: b.market,
    banners: b.banner.map((banner) => ({
      sectionId: banner.sectionId,
      url: banner.url,
      imageSource: getLargestImageUrl(banner.banner as Media),
    })),
  }));

  return parsedResponse;
};
