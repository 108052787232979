import { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useQuery } from 'react-query';
import { getOperationGeneralTabData } from 'src/services/operation/operationCr';
import { GeneralData } from 'src/types/operations/cr';

import GeneralDataItem from '../../../../components/GeneralDataItem';
import GeneralDataItemConditional from '../../../../components/GeneralDataItemConditional';
import { If } from '../../../../components/If';
import { PanelLogin } from '../../../../components/PanelLogin';
import Subtitle from '../../../../components/Subtitle';
import { useAuthContext } from '../../../../context/AuthContextProvider';

export default function OperationCRGeneralData({ hashId }: { hashId: string }) {
  const { auth } = useAuthContext();
  const [data, setData] = useState<GeneralData>({} as GeneralData);
  const { isLoading, error } = useQuery({
    queryKey: ['operationGeneral'],
    queryFn: () => getOperationGeneralTabData(hashId),
    onSuccess: (response) => {
      setData(response);
    },
  });

  if (isLoading) return null;
  if (error) return null;

  return (
    <Box p={2}>
      <Subtitle title="Dados Gerais" />
      <Box>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_cr_dados_gerais'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions[
              'operacoes_cr_dados_gerais'
            ]
          }>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="CNPJ da Securitizadora"
                subtitle={data?.securitizerCnpj}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Securitizadora"
                subtitle={data?.securitizerName}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Nº da Emissão"
                subtitle={data?.operationEmissionNumber}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Nº das séries"
                subtitle={data?.operationSeriesNumber}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Quantidade de séries"
                subtitle={data?.operationSeriesQuantity}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Tipo de Estrutura"
                subtitle={data?.operationStructureType || 'N/D'}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Plataforma de Crowdfunding"
                subtitle={data?.crowdfunding || 'N/D'}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItem
                title="Data de início"
                subtitle={data?.operationStartDate}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItemConditional
                title="Regime Fiduciário"
                condition={data?.isFiduciaryRegiment}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItemConditional
                title="Multiclasses"
                condition={data?.isMulticlasses}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <GeneralDataItemConditional
                title="Multiseries"
                condition={data?.isMultiseries}
              />
            </Grid>
          </Grid>
        </If>
      </Box>
    </Box>
  );
}
