import { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useQuery } from 'react-query';
import { getOperationResumeTabData } from 'src/services/operation/operationCr';
import { OperationResume } from 'src/types/operations/cr';

import OperationCRResumeGeneralData from './General';
import OperationCRResumeReceivables from './Receivables';
import OperationCRResumeServiceProvider from './ServiceProvider';
import OperationCRResumeTitles from './Titles';

export default function OperationCRResume({ hashId }: { hashId: string }) {
  const [data, setData] = useState<OperationResume>({} as OperationResume);
  const { isLoading, error } = useQuery({
    queryKey: ['operationResume', hashId],
    queryFn: () => getOperationResumeTabData(hashId),
    onSuccess: (response) => {
      setData(response);
    },
  });

  useEffect(() => {}, []);

  return (
    <Box p={1}>
      <Grid container>
        <Grid item xs={12} md>
          <OperationCRResumeGeneralData general={data?.general} />
        </Grid>
        <Grid item xs={12} md>
          <OperationCRResumeServiceProvider
            serviceProviders={data?.serviceProviders}
          />
        </Grid>
        <Grid item xs={12}>
          <OperationCRResumeReceivables
            receivables={data?.receivables}
            isLoading={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <OperationCRResumeTitles
            titles={data?.titles}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
