import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
} from '@mui/material';

const MarketsNavigation = ({
  value,
  handleSelectMarket,
  filters,
  markets = ['CR', 'CRI', 'CRA', 'FII', 'FIDC'],
}: {
  value: string;
  handleSelectMarket: (value: string) => void;
  filters?: any;
  markets?: string[];
}) => {
  return (
    <Box>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          handleSelectMarket(newValue);
        }}
        showLabels
        sx={(theme) => ({
          height: 48,
          width: 'max-content',
          justifyContent: 'flex-start',
          [theme.breakpoints.down('sm')]: { width: 'auto' },
        })}>
        {markets?.map((market, index) => (
          <BottomNavigationAction
            key={`${market}-${index}`}
            label={market?.toUpperCase()}
            value={market}
            disabled={Boolean(
              filters?.[market]?.rankings &&
                !filters?.[market]?.rankings?.length,
            )}
          />
        ))}
      </BottomNavigation>
      <Divider />
    </Box>
  );
};

export default MarketsNavigation;
