// Declarative routing
import { Box } from '@material-ui/core';
import { useParams } from 'react-router';

// Material ui components
// Global components
import OperationCR from './OperationCR';
import OperationCRA from './OperationCRA';
import OperationCRI from './OperationCRI';
import OperationFIDC from './OperationFIDC';
import OperationFII from './OperationFII';
import { ScrollToTop } from '../../utils/scroll';

export default function Operation() {
  const { market } = useParams();

  return (
    <ScrollToTop>
      <Box component="section">
        {market === 'fidc' && <OperationFIDC />}
        {market === 'cri' && <OperationCRI />}
        {market === 'cra' && <OperationCRA />}
        {market === 'fii' && <OperationFII />}
        {market === 'cr' && <OperationCR />}
      </Box>
    </ScrollToTop>
  );
}
