import { Box, Grid } from '@mui/material';
import GeneralDataItem from 'src/components/GeneralDataItem';
import Subtitle from 'src/components/Subtitle';
import { OperationResume } from 'src/types/operations/cr';

type OperationCRAResumeGeneralDataProps = Pick<OperationResume, 'general'>;

export default function OperationCRResumeGeneralData({
  general,
}: OperationCRAResumeGeneralDataProps) {
  return (
    <Box p={1}>
      <Subtitle title="Dados Gerais" />
      <Box py={4}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <GeneralDataItem title="Emissor" subtitle={general?.securitizer} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <GeneralDataItem
              title="Nº da Emissão"
              subtitle={general?.emissionNumber}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <GeneralDataItem
              title="Nº das séries"
              subtitle={general?.seriesNumber}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <GeneralDataItem
              title="Plataforma de Crowdfunding"
              subtitle={general?.crowdfunding || 'N/D'}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
