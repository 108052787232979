import { Box, Grid } from '@mui/material';
import SubTitle from 'src/components/v2/SubTitle';
import { OperationAsset } from 'src/types/operations/cr';

import PaperPanelInfo from '../../../../../components/PaperPanelInfo';

type OperationCRAssetCharacteristicsProps = Pick<
  OperationAsset,
  'characteristics'
> & {
  isLoading: boolean;
};

export function OperationCRAssetCharacteristics({
  characteristics,
  isLoading,
}: OperationCRAssetCharacteristicsProps) {
  if (!characteristics) return null;

  return (
    <div>
      <SubTitle title="Características" />
      <Box py={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              first
              title="Ativo Lastro"
              value={characteristics?.operationProduct}
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              title="Categoria de Risco de Crédito"
              value={characteristics?.operationCreditRiskCategory}
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              title="Tipo de Devedor"
              value={characteristics?.operationDebtor}
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PaperPanelInfo
              title="Parecer ESG"
              value={characteristics?.operationFeedbackESG}
              loading={isLoading}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
