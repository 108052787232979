import { useEffect, useRef, useState } from 'react';

import { Skeleton } from '@material-ui/lab';
import { Box, Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import { User } from 'src/types/user';
import { pickData } from 'src/utils/object';

import AdvancedView from './AdvancedView';
import BasicView from './BasicView';
import OnboardingModal from '../../components/OnboardingModal';
import MetaTags from '../../components/Seo/MetaTags';
import MarketsNavigation from '../../components/UI/MarketsNavigation/MarketsNavigation';
import SubHeader from '../../components/UI/SubHeader/SubHeader';
import { useAuthContext } from '../../context/AuthContextProvider';
import { getInformativeTextByLocation } from '../../services/informative';
import { countOperations } from '../../services/operation';
import { updateUser } from '../../services/user';
import { usePreventCopyAndSelect, useQueryParams } from '../../utils/hooks';
import { ScrollToTop } from '../../utils/scroll';
import { allowedFields } from '../AccountProfile/UserData/LegalPersonForm';

export type QueryParams = {
  market: 'CR' | 'CRI' | 'CRA' | 'FII' | 'FIDC' | '';
  mode: 'basic' | 'advanced' | '';
  indicator: string;
  filters: any;
  shared?: string;
};

export default function AllOperations() {
  const { auth } = useAuthContext();
  usePreventCopyAndSelect();
  const [{ query }, setQuery] = useQueryParams<QueryParams>({
    market: '',
    mode: '',
    indicator: '',
    filters: '',
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const updateUserMutation = useMutation(updateUser, {
    onSuccess: () => {
      auth.refetch();
    },
  });

  const operationRef = useRef(null);

  const { data: infoData } = useQuery(['operations', 'info'], () =>
    getInformativeTextByLocation('operation'),
  );

  const { data: operationInfo, isLoading } = useQuery(
    ['operations', 'count', query.market],
    () =>
      countOperations({
        market: query.market ? query.market.toLowerCase() : '',
      }),
    {
      enabled: !!query.market,
    },
  );

  const handleSelectMarket = (market: string) => {
    setQuery(
      {
        ...query,
        mode: market === 'CR' ? 'basic' : query.mode,
        indicator: market === 'CR' ? '' : query.indicator,
        market,
      },
      'push',
    );
  };

  const handleSelectView = (mode: string) => {
    setQuery({
      ...query,
      mode,
      indicator: null,
      shared: null,
    });
  };

  const handlePrint = useReactToPrint({
    content: () => operationRef.current,
  });

  useEffect(() => {
    if (
      !query.mode ||
      (query.mode === 'advanced' &&
        !!auth.user &&
        !auth?.user?.subscription?.plan?.permissions?.operacoes_visao_avancada)
    ) {
      setQuery({
        market: 'CR',
        mode: 'basic',
        indicator: '',
        filters: '',
      });
    }
  }, [query.mode]);

  useEffect(() => {
    if (
      (auth?.user?.subscription?.plan?.permissions?.operacoes_visao_basica ||
        auth?.user?.subscription?.plan?.permissions
          ?.operacoes_visao_avancada) &&
      !auth.user?.onboarding?.operations
    ) {
      setOpen(true);
      const pickedData = pickData(
        {
          ...auth.user,
          onboarding: {
            ...auth.user.onboarding,
            operations: new Date(),
          },
        },
        allowedFields,
      ) as User;
      updateUserMutation.mutateAsync({ data: pickedData });
    }
  }, [auth.user?.onboarding?.operations]);

  return (
    <ScrollToTop>
      <Box>
        <OnboardingModal
          open={open}
          handleClose={handleClose}
          type="operations"
        />
      </Box>
      <Box
        id="operations-page"
        ref={operationRef}
        sx={{
          '@media print': {
            zoom: '80%',
          },
        }}>
        <MetaTags
          title="Operações"
          description="Encontre as principais operações dos mercados de crédito e de capitais. Pesquise por apelido e filtre por diversos diversas informações."
        />
        <SubHeader
          title="Operações"
          subTitle={infoData?.data?.operation?.informationText}
          breadcrumbs1="Operações"
        />
        <Paper
          elevation={0}
          square
          sx={(theme) => ({
            padding: theme.spacing(3, 4),
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(3, 2),
            },
          })}>
          <MarketsNavigation
            value={query.market}
            handleSelectMarket={handleSelectMarket}
          />
          <Box id="operations-content" p={2}>
            <Box m="40px 0px 32px">
              {isLoading ? (
                <Skeleton variant="text" width={100} />
              ) : (
                <Typography
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: 600,
                    lineHeight: '29.05px',
                  }}>
                  {`${query.market}s`}
                </Typography>
              )}
            </Box>
            <Box mb={5.25}>
              {isLoading ? (
                <Skeleton variant="text" width={100} />
              ) : (
                <Typography
                  sx={(theme) => ({
                    color: '#1D1D1D',
                    marginBottom: 0,
                    '& > p': {
                      margin: 0,
                    },
                    '& > ul > li ~ li': {
                      marginTop: 1,
                    },
                    [theme.breakpoints.down('xs')]: {
                      marginTop: theme.spacing(1),
                    },
                  })}
                  dangerouslySetInnerHTML={{
                    __html: operationInfo?.description,
                  }}
                />
              )}
            </Box>
            <Box style={{ minHeight: 500 }}>
              {query.mode === 'advanced' && (
                <AdvancedView
                  state={query}
                  setState={setQuery}
                  operationInfo={operationInfo}
                  handlePrint={handlePrint}
                  handleSelectView={handleSelectView}
                  handleModalOpen={handleOpen}
                />
              )}
              {query.mode === 'basic' && (
                <BasicView
                  state={query}
                  setState={setQuery}
                  operationInfo={operationInfo}
                  handlePrint={handlePrint}
                  handleSelectView={handleSelectView}
                  handleModalOpen={handleOpen}
                />
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </ScrollToTop>
  );
}
