import { Grid } from '@mui/material';
import { format, parse } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import { If } from 'src/components/If';
import Export from 'src/pages/AllOperations/Export';
import { Institution } from 'src/types/institution';
import { OperationPerformance } from 'src/types/operation';
import { convertNumber } from 'src/utils/number';

import AmountIssuedChart from '../AmountIssued';
import FundNumberChart from '../FundNumber';
import NetWorthChart from '../NetWorth';

type Props = {
  market: 'FIDC' | 'FII' | 'CRA' | 'CRI' | 'CR';
  dateOptions: string[] | undefined;
  data: OperationPerformance[] | undefined;
  institution: Institution;
};

function ChartsByMarket({ market, dateOptions, data, institution }: Props) {
  const handleExport = () => {
    const csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      filename: `${institution.fantasyName}_${market}`,
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: ['FIDC', 'FII'].includes(market)
        ? ['Data de referência', 'Patrimônio líquido', 'Número de fundos']
        : ['Data de referência', 'Montante emitido', 'Número de fundos'],
    };

    const csvExporter = new ExportToCsv(csvOptions);

    if (data) {
      const dataToExport = data.map((row) => ({
        dataReferencia: format(
          parse(row.date, 'yyyy-MM-dd', new Date()),
          'MM/yyyy',
        ),
        value: convertNumber(row.value ?? 0),
        number: parseInt(row.number),
      }));

      csvExporter.generateCsv(dataToExport);
    }
  };

  return (
    <Grid container columnSpacing={8} rowSpacing={2}>
      <Grid item xs={12} container justifyContent="flex-end">
        <Export
          handleExport={handleExport}
          handlePrint={() => null}
          permission="instituicao_operacoes_exportar"
          hasData={!!data?.length}
          pdfEnabled={false}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <If condition={['FIDC', 'FII'].includes(market)}>
          <NetWorthChart dateOptions={dateOptions} data={data} />
        </If>
        <If condition={['CRA', 'CRI'].includes(market)}>
          <AmountIssuedChart dateOptions={dateOptions} data={data} />
        </If>
      </Grid>
      <Grid item xs={12} md={6}>
        <FundNumberChart
          dateOptions={dateOptions}
          data={data}
          market={market}
        />
      </Grid>
    </Grid>
  );
}

export default ChartsByMarket;
