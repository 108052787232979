import { useEffect, useState } from 'react';

import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import {
  Avatar,
  Badge,
  Box,
  Button,
  InputLabel,
  styled,
  Typography,
} from '@mui/material';
import { Media } from 'src/types/strapi';
import { getSmallestImageUrl } from 'src/utils/image';

import defaultImage from '../../assets/imgs/uploadImage.png';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `3px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    width: '40px',
    height: '40px',
    borderRadius: '20px',
  },
}));

function ImageInput({
  name,
  value,
  setValue,
  image,
  setImage,
  error,
  helperText,
  type,
  width = '160px',
  height = '160px',
}: {
  image?: Media | null | undefined;
  setImage?: (value: any) => void;
  name: string;
  value: File | null;
  setValue: (field: string, value: any) => void;
  error?: boolean;
  helperText?: string;
  type: 'circular' | 'square';
  width?: string;
  height?: string;
}) {
  const [preview, setPreview] = useState<string | undefined>();

  useEffect(() => {
    if (!value) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(value);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [value]);

  const handleClear = () => {
    if (setImage) {
      setImage(null);
    }
    setPreview(undefined);
    setValue(name, null);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">
      <input
        name={name}
        accept=".png, .jpg, .jpeg"
        id="button-file"
        type="file"
        onChange={(event) => {
          if (event.target.files) {
            setValue(name, event.target.files[0]);
          }
        }}
        hidden
      />
      <StyledBadge
        overlap={type === 'circular' ? 'circular' : undefined}
        color="primary"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <InputLabel
            sx={{ lineHeight: 0, color: '#FFFFFF', cursor: 'pointer' }}
            htmlFor="button-file">
            <AddAPhotoOutlinedIcon fontSize="small" />
          </InputLabel>
        }>
        <Avatar
          variant={type}
          imgProps={{
            sx: {
              height: 'unset',
            },
          }}
          sx={(theme) => ({
            backgroundColor: theme.palette.primary.contrastText,
            width,
            height,
            objectFit: 'contain',
            boxShadow: theme.shadows[2],
            [theme.breakpoints.down('sm')]: {
              width: '120px',
              height: '120px',
            },
          })}
          src={preview ?? getSmallestImageUrl(image) ?? defaultImage}
        />
      </StyledBadge>
      {preview || image ? (
        <Button
          variant="text"
          sx={(theme) => ({
            fontSize: '12px',
            [theme.breakpoints.down('sm')]: { fontSize: '10px' },
          })}
          onClick={handleClear}>
          Remover imagem
        </Button>
      ) : undefined}
      {error && (
        <Typography fontSize="0.75rem" color="error">
          {helperText}
        </Typography>
      )}
    </Box>
  );
}

export default ImageInput;
