// Material ui components
// Apollo client
import { useQuery } from '@apollo/client';
import { Box, Grid, makeStyles } from '@material-ui/core';

// Global components
import CardServiceProviderDivider from '../../../../components/CardServiceProviderDivider';
import { If } from '../../../../components/If';
import { PanelLogin } from '../../../../components/PanelLogin';
import Subtitle from '../../../../components/Subtitle';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import ipanema from '../../../../graphql/clients/ipanema';
import { GET_DETAILS_CRA_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID } from '../../../../graphql/queries';

// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.common.black,
  },
}));

export default function OperationCRAServiceProvider({ hashId }) {
  const { auth } = useAuthContext();
  const classes = useStyles();
  const { data, loading, error } = useQuery(
    GET_DETAILS_CRA_SERVICE_PROVIDERS_BY_OPERATION_HASH_ID,
    {
      variables: { hash: hashId },
      client: ipanema,
      skip: !auth?.user?.subscription?.plan?.permissions[
        'operacoes_cra_prestadores_de_servicos'
      ],
    },
  );

  return (
    <Box p={2}>
      <Box>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_cra_prestadores_de_servicos'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions[
              'operacoes_cra_prestadores_de_servicos'
            ]
          }>
          <Grid container spacing={3}>
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsCraServiceProvidersByOperationHashId
                      ?.securitizers?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Securitizadora" />
                  </Grid>
                  {data?.getDetailsCraServiceProvidersByOperationHashId?.securitizers?.map(
                    (securitizer, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Securitizadora"
                          classes={classes}
                          {...securitizer}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsCraServiceProvidersByOperationHashId
                      ?.fiduciaryAgents?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Agente Fiduciário" />
                  </Grid>
                  {data?.getDetailsCraServiceProvidersByOperationHashId?.fiduciaryAgents?.map(
                    (fiduciaryAgent, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Agente Fiduciário"
                          classes={classes}
                          {...fiduciaryAgent}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsCraServiceProvidersByOperationHashId
                      ?.legalAdvisors?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Assessor Jurídico" />
                  </Grid>
                  {data?.getDetailsCraServiceProvidersByOperationHashId?.legalAdvisors?.map(
                    (legalAdvisor, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Assessor Jurídico"
                          classes={classes}
                          {...legalAdvisor}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsCraServiceProvidersByOperationHashId
                      ?.servicers?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Prestadores de serviço" />
                  </Grid>
                  {data?.getDetailsCraServiceProvidersByOperationHashId?.servicers?.map(
                    (servicer, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Prestadores de serviço"
                          classes={classes}
                          {...servicer}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
            {loading || error ? null : (
              <>
                <If
                  condition={
                    data?.getDetailsCraServiceProvidersByOperationHashId
                      ?.auditors?.length
                  }>
                  <Grid item xs={12} sm={12} md={12}>
                    <Subtitle title="Auditor" />
                  </Grid>
                  {data?.getDetailsCraServiceProvidersByOperationHashId?.auditors?.map(
                    (auditor, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardServiceProviderDivider
                          serviceProvider="Auditor"
                          classes={classes}
                          {...auditor}
                        />
                      </Grid>
                    ),
                  )}
                </If>
              </>
            )}
          </Grid>
        </If>
      </Box>
    </Box>
  );
}
