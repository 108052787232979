import { Box, Divider, Grid, Typography } from '@mui/material';
import { OperationResume } from 'src/types/operations/cr';

import PaperPanelInfo from '../../../../../components/PaperPanelInfo';

type OperationCRResumeReceivablesProps = Pick<
  OperationResume,
  'receivables'
> & {
  isLoading: boolean;
};

export default function OperationCRResumeReceivables({
  receivables,
  isLoading,
}: OperationCRResumeReceivablesProps) {
  return (
    <Box p={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="subtitle2"
          sx={(theme) => ({
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(20),
          })}>
          &mdash;Recebíveis
        </Typography>
      </Box>
      <Divider
        sx={(theme) => ({
          fontSize: '1rem',
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        })}
      />
      <Box py={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              first
              title="Ativo Lastro"
              value={receivables?.operationProduct}
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              title="Categoria de Risco de Crédito"
              value={receivables?.operationCreditRiskCategory}
              loading={isLoading}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg>
            <PaperPanelInfo
              title="Devedor"
              value={receivables?.operationDebtor}
              loading={isLoading}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
