import { useMemo, useState } from 'react';

import { Link, Typography } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { FavoriteRowButton } from 'src/components/FavoriteRowButton';
import SubTitle from 'src/components/v2/SubTitle';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getFilteredOperations } from 'src/services/institution';
import { Institution } from 'src/types/institution';
import { convertValue } from 'src/utils/number';
import { makeColumnFilterModeOptions } from 'src/utils/table';

import OperationsTable from './OperationsTable';

type Props = {
  market: 'FIDC' | 'FII' | 'CRA' | 'CRI' | 'CR';
  institution: Institution;
};

function Tables({ market, institution }: Props) {
  const { auth } = useAuthContext();
  const [data, setData] = useState<any[]>([]);
  const hasPermission =
    !!auth?.user?.subscription?.plan?.permissions?.instituicao_operacoes;
  const { isLoading, isFetching } = useQuery(
    ['filteredOperations', market],
    () => getFilteredOperations(institution, market),
    {
      enabled: hasPermission,
      onSuccess: (data) => {
        setData(data?.data);
      },
    },
  );

  const handleAddFavorite = (rowId: number, favorite: any) => {
    setData((prev) => {
      const favoritedItem = prev.map((item) => {
        if (item.operation.id === rowId) {
          return { ...item, favorite };
        }

        return item;
      });

      return favoritedItem;
    });
  };

  const handleRemoveFavorite = (rowId: number) => {
    setData((prev) => {
      const favoritedItem = prev.map((item) => {
        if (item.operation.id === rowId) {
          return { ...item, favorite: undefined };
        }

        return item;
      });

      return favoritedItem;
    });
  };

  const columns: MRT_ColumnDef<any>[] = useMemo(
    () => [
      {
        accessorKey: 'favorite',
        accessorFn: (row) => row.operation,
        header: '',
        maxSize: 50,
        enableColumnFilter: false,
        enableSorting: false,
        enableColumnActions: false,
        enableResizing: false,
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? (
            <FavoriteRowButton
              type="operation"
              rowId={cell.getValue()?.id}
              favoriteId={cell.row.original?.favorite?.id}
              handleAddFavorite={handleAddFavorite}
              handleRemoveFavorite={handleRemoveFavorite}
            />
          ) : (
            ''
          ),
      },
      {
        accessorKey: 'operation.name',
        accessorFn: (row) => row.operation.name,
        header: 'Operação',
        ...makeColumnFilterModeOptions('string'),
      },
      {
        accessorKey: 'value',
        accessorFn: (row) => row.value ?? 0,
        header: ['FIDC', 'FII'].includes(market)
          ? 'Patrimônio líquido'
          : 'Montante emitido',
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? convertValue(cell.getValue()) : '-',
        ...makeColumnFilterModeOptions('number'),
      },
      {
        accessorKey: 'date',
        accessorFn: (row) => {
          if (!row.date) return '-';
          return Number(row.date.replaceAll('-', ''));
        },

        header: 'Data de referência',
        Cell: ({ cell }) =>
          cell
            .getValue<number>()
            ?.toString(10)
            .replace(/^(\d{4})(\d{2})(\d{2})$/, '$3/$2/$1'),
        ...makeColumnFilterModeOptions('number'),
      },
      {
        accessorKey: 'performance.name',
        header: 'Área de atuação',
        accessorFn: (row) => row.performance.name,
        ...makeColumnFilterModeOptions('string'),
      },
      {
        accessorKey: 'product',
        accessorFn: (row) => row.product ?? '-',
        header: market === 'CR' ? 'Ativo Lastro' : 'Tipo de ativo',
        ...makeColumnFilterModeOptions('string'),
      },
      {
        accessorKey: 'operation',
        header: 'Ação',
        enableColumnFilter: false,
        enableSorting: false,
        enableColumnActions: false,
        enableResizing: false,
        Cell: ({ cell }: { cell: any }) =>
          cell.getValue() ? (
            <Link
              component={RouterLink}
              to={`/operacao/${(cell.getValue()?.market?.name || market)?.toLowerCase()}/${
                cell.getValue()?.slug
              }/${cell.getValue()?.hashId}`}>
              Ver operação
            </Link>
          ) : (
            'Operação não encontrada'
          ),
      },
    ],
    [market],
  );

  return (
    <>
      <SubTitle title="Lista de operações" />
      <Typography>
        {`Nessa aba você encontra todas as operações vinculadas à instituição ${institution.fantasyName} 
          de acordo com o perfil de serviço prestado à respectiva operação.`}
      </Typography>
      <OperationsTable
        isLoading={isLoading || isFetching}
        columns={columns}
        data={data}
        institutionName={institution.fantasyName}
        market={market}
        hasPermission={hasPermission}
      />
    </>
  );
}

export default Tables;
