import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { Box, Divider, Switch, Tooltip, Typography } from '@mui/material';
import themev5 from 'src/themev5';

type SubtitleProps = {
  title: string;
  isIncongruitiesAlert?: boolean;
  hasSelectType?: boolean;
  isSelectedType?: boolean;
  handleChangeType?: () => void;
  titleType1?: string;
  titleType2?: string;
};

export default function Subtitle({
  title,
  isIncongruitiesAlert,
  hasSelectType,
  isSelectedType,
  handleChangeType,
  titleType1,
  titleType2,
}: SubtitleProps) {
  return (
    <Box my={3}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="subtitle2"
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          &mdash;{title}
        </Typography>
        {isIncongruitiesAlert && (
          <Tooltip
            disableFocusListener
            disableTouchListener
            arrow
            title="Alerta para dados com incongruências"
            placement="right">
            <ReportProblemOutlinedIcon
              style={{ marginLeft: themev5.spacing(1) }}
              color="secondary"
              titleAccess="alerta"
            />
          </Tooltip>
        )}
        {hasSelectType && (
          <Box>
            <span>{titleType1}</span>
            <Switch
              color="secondary"
              checked={isSelectedType}
              onChange={handleChangeType}
              inputProps={{ 'aria-label': 'trocar tipo de exibição' }}
              style={{ color: '#000' }}
            />
            <span>{titleType2}</span>
          </Box>
        )}
      </Box>
      <Divider
        sx={(theme) => ({
          height: 2,
          backgroundColor: theme.palette.common.black,
        })}
      />
    </Box>
  );
}
