import { Box, Grid } from '@material-ui/core';
import {
  OperationLiabilityEmission,
  OperationLiabilityTitle,
} from 'src/types/operations/cr';

import PaperPanelInfo from '../../../../../components/PaperPanelInfo';
import Subtitle from '../../../../../components/Subtitle';

type OperationCRLiabilityDataProps = {
  data: OperationLiabilityTitle[];
  emission: OperationLiabilityEmission[];
  isLoading: boolean;
};

export function OperationCRLiabilityData({
  data,
  emission,
  isLoading,
}: OperationCRLiabilityDataProps) {
  const totalAmountIssued = data.reduce(
    (acc, item) => acc + parseFloat(item.amountIssued),
    0,
  );

  return (
    <div>
      <Subtitle title="Dados" />
      <Box py={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} lg>
            <PaperPanelInfo
              numeric
              title="Emissões Totais"
              value={totalAmountIssued}
              loading={isLoading}
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
