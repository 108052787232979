import { ChangeEvent, useState } from 'react';

import { Box, Grid, Pagination, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import SkeletonGrid from 'src/components/SkeletonGrid';
import Subtitle from 'src/components/v2/SubTitle';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getOperationAssetAssignorsData } from 'src/services/operation/operationCr';
import { AssetAssignor as AssetAssignorType } from 'src/types/operations/cr';

import { AssetAssignorCard } from './AssetAssignorCard';

const PAGE_SIZE = 12;

type OperationCRAssetAssignors = {
  hashId: string;
  market: string;
};

export function OperationCRAssetAssignors({
  hashId,
  market,
}: OperationCRAssetAssignors) {
  const queryKeyName = `operation-${market.toLowerCase()}-assets-assignors`;
  const history = useHistory();
  const { auth } = useAuthContext();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [assetAssignors, setAssetAssignors] = useState<AssetAssignorType[]>([]);

  const { isLoading } = useQuery({
    queryKey: [queryKeyName, hashId, page],
    queryFn: async () =>
      await getOperationAssetAssignorsData({
        hashId,
        page,
        pageSize: PAGE_SIZE,
      }),
    onSuccess(data) {
      setAssetAssignors(data.data);
      setPage(data.meta.pagination.page);
      setPageCount(data.meta.pagination.pageCount);
    },
  });

  function handleAssignorClick(assignor: AssetAssignorType) {
    if (assignor.strapiId && assignor.slug) {
      if (assignor.market) {
        history.push(
          `/operacao/${market.toLowerCase()}/${assignor.slug}/${assignor.strapiId}`,
        );
        return;
      }

      history.push(`/instituicao/${assignor.slug}/${assignor.strapiId}`);

      return;
    }

    return;
  }

  function hasLink(assignor: AssetAssignorType) {
    return !!(assignor.strapiId && assignor.slug);
  }

  const handleChange = (_: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <div>
      <Subtitle title="Cedentes" />
      {assetAssignors?.length ? (
        <Box py={2}>
          <Grid container spacing={1}>
            {isLoading ? (
              <SkeletonGrid
                width="23.5rem"
                height="7.5rem"
                skeletonNumber={3}
              />
            ) : (
              assetAssignors?.map((assignor) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  key={`${assignor.strapiId}-${market}-assignor`}
                  onClick={() => handleAssignorClick(assignor)}
                  sx={{
                    cursor: hasLink(assignor) ? 'pointer' : 'default',
                  }}>
                  <AssetAssignorCard
                    key={assignor.strapiId}
                    assignor={assignor}
                    isLoggedIn={auth.isLoggedIn}
                    hasLink={hasLink(assignor)}
                  />
                </Grid>
              ))
            )}
          </Grid>
          <Box pt={3} display="flex" justifyContent="flex-end">
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChange}
              shape="rounded"
            />
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="start" m={1}>
          <Typography>Não foram encontrados devedores</Typography>
        </Box>
      )}
    </div>
  );
}
