import { Chip, Typography } from '@mui/material';

export default function DateChip({
  title,
  date,
}: {
  title?: string;
  date: string;
  loading?: boolean;
}) {
  return (
    <>
      {title && (
        <Typography
          variant="subtitle1"
          sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
          {`${title}:`}
        </Typography>
      )}
      <Chip
        sx={(theme) => ({
          fontSize: '0.875rem',
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.common.white,
          marginLeft: theme.spacing(1),
        })}
        size="small"
        color="secondary"
        label={date ?? 'N/D'}
      />
    </>
  );
}
