import { Dispatch, SetStateAction, useState } from 'react';

import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import CardDemonstration from 'src/components/CardDemonstration/CardDemonstration';
import { If } from 'src/components/If';
import SubTitle from 'src/components/v2/SubTitle';
import { useAuthContext } from 'src/context/AuthContextProvider';
import { getAtuacoes, getChartDataOperations } from 'src/services/institution';
import { Institution } from 'src/types/institution';
import { Performance } from 'src/types/performance';
import { v4 as uuid } from 'uuid';

import ChartsByMarket from './ChartsByMarket';

type Props = {
  market: 'FIDC' | 'FII' | 'CRA' | 'CRI' | 'CR';
  institution: Institution;
  setPerformance: Dispatch<SetStateAction<string>>;
  performance: string;
};

function Charts({ market, institution, performance, setPerformance }: Props) {
  const { auth, upgradedPlanByPermission } = useAuthContext();
  const hasPermission =
    !!auth?.user?.subscription?.plan?.permissions?.instituicao_operacoes;
  const [options, setOptions] = useState<Performance[]>([]);
  const handleChange = (event: SelectChangeEvent) => {
    setPerformance(event.target.value as string);
  };

  const upgradedPlans = upgradedPlanByPermission('instituicao_operacoes');

  const { isLoading: isLoadingOptions } = useQuery(
    ['atuacao', market],
    () => getAtuacoes(),
    {
      onSuccess: (data) => {
        let performance: Performance[] = [];

        if (['CRI', 'CRA'].includes(market)) {
          performance = data?.filter((row: Performance) =>
            ['Securitizadora', 'Agente Fiduciário'].includes(row.externalCode),
          );
        }

        if (['FII'].includes(market)) {
          performance = data?.filter((row: Performance) =>
            ['Administrador', 'Gestor'].includes(row.externalCode),
          );
        }

        if (['FIDC'].includes(market)) {
          performance = data?.filter((row: Performance) =>
            ['Administrador', 'Gestor', 'Custodiante'].includes(
              row.externalCode,
            ),
          );
        }
        setOptions(performance);
        setPerformance(performance[0]?.externalCode);
      },
    },
  );

  const { data, isLoading } = useQuery(
    ['chartsDataOperation', institution.id, market, performance],
    () => getChartDataOperations(institution.id, market, performance),
    {
      enabled: !!performance && hasPermission,
    },
  );

  const dateOptions = data?.map((option) => option.date).reverse();

  return (
    <>
      <SubTitle title="Consolidado de operações" />
      {!isLoadingOptions && options.length !== 0 && (
        <FormControl variant="standard" sx={{ width: 164, marginBottom: 6 }}>
          <Select
            placeholder="Área de atuação"
            value={performance}
            onChange={handleChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 245,
                },
              },
            }}>
            {options?.length &&
              options.map((option: Performance) => (
                <MenuItem key={uuid()} value={option.externalCode}>
                  {option.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}

      <li>
        <Typography sx={{ display: 'inline' }}>
          Ao selecionar a área de atuação no filtro acima você consegue
          visualizar o consolidado de operações da instituição.
        </Typography>
      </li>
      <If condition={hasPermission}>
        <If condition={isLoading}>
          <Box sx={{ margin: '60px 0 58px' }}>
            <CircularProgress color="primary" />
          </Box>
        </If>
        <If condition={!isLoading && data?.length}>
          <Box sx={{ margin: '60px 0 58px' }}>
            <ChartsByMarket
              market={market}
              institution={institution}
              dateOptions={dateOptions}
              data={data}
            />
          </Box>
        </If>
        <If condition={!isLoading && !data?.length}>
          <Typography sx={{ padding: '32px 0 40px' }}>
            Ops! No momento ainda não temos dados consolidados para exibir a
            área de atuação selecionada. Estamos trabalhando para trazer os
            dados o mais breve possível.
          </Typography>
        </If>
      </If>
      <If condition={!hasPermission}>
        <CardDemonstration
          title={`Recurso disponível apenas no(s) plano ${upgradedPlans}`}
          subTitle={
            'Faça um upgrade em sua assinatura para ter acesso às operações desta instituição.'
          }
          labelButton={'Fazer upgrade'}
          url={'/planos'}
        />
      </If>
    </>
  );
}

export default Charts;
