import { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { format } from 'date-fns';
import { uniq } from 'lodash';
import { useQuery } from 'react-query';
import { findManyInstitutionByCNPJs } from 'src/services/institution';
import { getOperationServiceProviderTabData } from 'src/services/operation/operationCr';
import { Institution } from 'src/types/institution';
import { ServiceProviderData } from 'src/types/operations/cr';
import { formatCNPJ } from 'src/utils/document';

import CardServiceProviderDivider from '../../../../components/CardServiceProviderDivider';
import { If } from '../../../../components/If';
import { PanelLogin } from '../../../../components/PanelLogin';
import Subtitle from '../../../../components/Subtitle';
import { useAuthContext } from '../../../../context/AuthContextProvider';

export default function OperationCRServiceProvider({
  hashId,
}: {
  hashId: string;
}) {
  const { auth } = useAuthContext();
  const [serviceProviderData, setServiceProviderData] =
    useState<ServiceProviderData>();

  const [parsedServiceProviderData, setParsedServiceProviderData] =
    useState<ServiceProviderData>();

  const filterServiceProviders = (
    data: ServiceProviderData | undefined,
    type: keyof ServiceProviderData,
  ) => {
    return (
      data?.[type]?.filter(
        (provider) => provider?.name?.length || provider?.logo,
      ) || []
    );
  };

  const getCNPJs = (
    data: ServiceProviderData | undefined,
    type: keyof ServiceProviderData,
  ) => {
    return data?.[type]?.map((item) => formatCNPJ(item.cnpj)) || [];
  };

  const mapServiceProviders = (
    institutions: Institution[],
    serviceProviderData: ServiceProviderData | undefined,
    type: keyof ServiceProviderData,
  ) => {
    const mappedInstitutions = institutions
      .filter((institution) =>
        serviceProviderData?.[type]?.some(
          (provider) => formatCNPJ(provider.cnpj) === institution.cnpj,
        ),
      )
      .map((institution) => {
        const provider = serviceProviderData?.[type]?.find(
          (provider) => formatCNPJ(provider.cnpj) === institution.cnpj,
        );
        return {
          hashId: institution.id.toString(),
          cnpj: institution.cnpj,
          slug: institution.slug,
          name: provider?.name,
          startDate: institution.creationDate
            ? format(new Date(institution.creationDate), 'yyyy-MM-dd')
            : '',
          logo: institution?.logo,
        };
      });

    if (mappedInstitutions?.length) {
      return mappedInstitutions;
    }

    return serviceProviderData?.[type];
  };

  const { isLoading, error } = useQuery({
    queryKey: ['operationServiceProvider', hashId],
    queryFn: () => getOperationServiceProviderTabData(hashId),
    onSuccess(data) {
      setServiceProviderData({
        auditors: filterServiceProviders(data, 'auditors'),
        fiduciaryAgents: filterServiceProviders(data, 'fiduciaryAgents'),
        legalAdvisors: filterServiceProviders(data, 'legalAdvisors'),
        securitizers: filterServiceProviders(data, 'securitizers'),
        servicers: filterServiceProviders(data, 'servicers'),
      });
    },
  });

  const { refetch: refetchInstitutions, isLoading: isLoadingInstitutions } =
    useQuery({
      queryKey: [
        'institutions',
        ...[
          'securitizers',
          'fiduciaryAgents',
          'legalAdvisors',
          'servicers',
          'auditors',
        ].map((type) =>
          getCNPJs(serviceProviderData, type as keyof ServiceProviderData),
        ),
      ],
      queryFn: () => {
        const uniqueCNPJs = uniq([
          ...getCNPJs(serviceProviderData, 'fiduciaryAgents'),
          ...getCNPJs(serviceProviderData, 'securitizers'),
          ...getCNPJs(serviceProviderData, 'legalAdvisors'),
          ...getCNPJs(serviceProviderData, 'servicers'),
          ...getCNPJs(serviceProviderData, 'auditors'),
        ]);

        return findManyInstitutionByCNPJs(uniqueCNPJs);
      },
      onSuccess(data) {
        const serviceProviders: ServiceProviderData = {
          securitizers:
            mapServiceProviders(data, serviceProviderData, 'securitizers') ||
            serviceProviderData?.securitizers ||
            [],
          auditors:
            mapServiceProviders(data, serviceProviderData, 'auditors') ||
            serviceProviderData?.auditors ||
            [],
          fiduciaryAgents:
            mapServiceProviders(data, serviceProviderData, 'fiduciaryAgents') ||
            serviceProviderData?.fiduciaryAgents ||
            [],
          legalAdvisors:
            mapServiceProviders(data, serviceProviderData, 'legalAdvisors') ||
            serviceProviderData?.legalAdvisors ||
            [],
          servicers:
            mapServiceProviders(data, serviceProviderData, 'servicers') ||
            serviceProviderData?.servicers ||
            [],
        };

        setParsedServiceProviderData(serviceProviders);
      },
    });

  useEffect(() => {
    refetchInstitutions();
  }, [serviceProviderData]);

  const renderServiceProviders = (
    title: string,
    data: ServiceProviderData[keyof ServiceProviderData] | undefined,
  ) => {
    return (
      <>
        <If condition={data?.length}>
          <Grid item xs={12} sm={12} md={12}>
            <Subtitle title={title} />
          </Grid>
          {data?.map((provider) => (
            <Grid item xs={12} sm={6} md={4} key={provider.hashId}>
              <CardServiceProviderDivider
                key={provider.hashId}
                serviceProvider={title}
                {...provider}
              />
            </Grid>
          ))}
        </If>
      </>
    );
  };

  return (
    <Box p={2}>
      <Box>
        <If
          condition={
            !auth?.user?.subscription?.plan?.permissions[
              'operacoes_cr_prestadores_de_servicos'
            ]
          }>
          <PanelLogin />
        </If>
        <If
          condition={
            auth?.user?.subscription?.plan?.permissions[
              'operacoes_cr_prestadores_de_servicos'
            ]
          }>
          <Grid container spacing={3}>
            {isLoading || isLoadingInstitutions || error ? null : (
              <>
                {renderServiceProviders(
                  'Securitizadora',
                  parsedServiceProviderData?.securitizers,
                )}
                {renderServiceProviders(
                  'Agente Fiduciário',
                  parsedServiceProviderData?.fiduciaryAgents,
                )}
                {renderServiceProviders(
                  'Assessor Jurídico',
                  parsedServiceProviderData?.legalAdvisors,
                )}
                {renderServiceProviders(
                  'Prestadores de serviço',
                  parsedServiceProviderData?.servicers,
                )}
                {renderServiceProviders(
                  'Auditor',
                  parsedServiceProviderData?.auditors,
                )}
              </>
            )}
          </Grid>
        </If>
      </Box>
    </Box>
  );
}
