import qs from 'qs';
import { api } from 'src/api/api';
import { StrapiResponse } from 'src/types/http';
import {
  AssetAssignor,
  GeneralData,
  OperationAsset,
  OperationInstitutionView,
  OperationLiability,
  OperationResume,
  ServiceProviderData,
} from 'src/types/operations/cr';

export const getOperationByInstitution = async (cnpj: string) => {
  const query = qs.stringify(
    {
      filters: {
        cnpj,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  return await api.get<OperationInstitutionView[]>(
    `/ipanema-two/operation/get-operation-by-institution?${query}`,
  );
};

export const getOperationResumeTabData = async (hashId: string) => {
  const query = qs.stringify(
    {
      filters: {
        operationId: hashId,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get<OperationResume>(
    `/ipanema-two/operation/get-resume?${query}`,
  );

  return data;
};

export const getOperationGeneralTabData = async (hashId: string) => {
  const query = qs.stringify(
    {
      filters: {
        operationId: hashId,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get<GeneralData>(
    `/ipanema-two/operation/get-general?${query}`,
  );
  return data;
};

export const getOperationServiceProviderTabData = async (hashId: string) => {
  const query = qs.stringify(
    {
      filters: {
        operationId: hashId,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get<ServiceProviderData>(
    `/ipanema-two/operation/get-service-provider?${query}`,
  );
  return data;
};

export const getOperationAssetTabData = async (hashId: string) => {
  const query = qs.stringify(
    {
      filters: {
        operationId: hashId,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get<OperationAsset>(
    `/ipanema-two/operation/get-asset?${query}`,
  );
  return data;
};

export const getOperationAssetAssignorsData = async ({
  hashId,
  page,
  pageSize,
}: {
  hashId: string;
  page: number;
  pageSize: number;
}) => {
  const query = qs.stringify(
    {
      filters: {
        operationId: hashId,
      },
      pagination: {
        page,
        pageSize,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

  const { data } = await api.get<StrapiResponse<AssetAssignor[]>>(
    `/ipanema-two/operation/get-asset-assignor?${query}`,
  );

  return data;
};

export const getOperationLiabilityTabData = async (hashId: string) => {
  const query = qs.stringify(
    {
      filters: {
        operationId: hashId,
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  const { data } = await api.get<OperationLiability>(
    `/ipanema-two/operation/get-liability?${query}`,
  );
  return data;
};
