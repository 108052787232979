import { useEffect, useState } from 'react';

import { SelectChangeEvent } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { format, parse } from 'date-fns';
import OperationCharts from 'src/components/OperationCharts';
import { OperationPerformance } from 'src/types/operation';
import { convertNumber } from 'src/utils/number';

type Props = {
  dateOptions: string[] | undefined;
  data: OperationPerformance[] | undefined;
  market: 'FIDC' | 'FII' | 'CRA' | 'CRI' | 'CR';
};

function FundNumberChart({ dateOptions, data, market }: Props) {
  const [period, setPeriod] = useState('');
  const handleChange = (event: SelectChangeEvent) => {
    setPeriod(event.target.value as string);
  };
  const selectedData = data?.find((op) => op.date === period);
  const xAxisCategories = dateOptions?.length ? [...dateOptions] : [];

  useEffect(() => {
    if (dateOptions?.length) {
      setPeriod(dateOptions[0]);
    }
  }, [dateOptions]);

  const series = [
    {
      data: data
        ?.sort((a, b) => {
          return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
        })
        ?.map((op) => op.number),
    },
  ];

  const options: ApexOptions = {
    chart: {
      fontFamily: 'Inter',
      id: 'netWorth',
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          strokeDashArray: 0,
          borderColor: '#E0E0E0',
          fillColor: '#E0E0E0',
          opacity: 0,
        },
      ],
    },
    noData: {
      text: 'Nenhum dado encontrado',
    },
    xaxis: {
      categories: xAxisCategories
        ?.sort((a, b) => {
          return a > b ? 1 : a < b ? -1 : 0;
        })
        .map((date) =>
          format(parse(date, 'yyyy-MM-dd', new Date()), 'MM/yyyy'),
        ),
    },
    colors: ['#165DFF'],
    tooltip: {
      theme: 'dark',
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: () => '',
        },
        formatter: (value) => convertNumber(value),
      },
      marker: {
        show: false,
      },
    },
  };

  return (
    <OperationCharts
      title={
        ['FIDC', 'FII'].includes(market)
          ? 'Número de fundos'
          : 'Número de operações'
      }
      subtitle={selectedData?.number}
      valueSelect={period}
      handleChangeSelect={handleChange}
      type="line"
      series={series}
      options={options}
      selectOptions={dateOptions}
      permission="instituicao_operacoes_exportar"
      handleExport={() => null}
      hasData={false}
      enabledExport={false}
    />
  );
}

export default FundNumberChart;
