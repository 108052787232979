import { CheckCircle, HighlightOff } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export default function GeneralDataItemConditional({
  title,
  condition,
  justify,
}: {
  title: string;
  condition: boolean;
  justify?: 'flex-start' | 'center' | 'flex-end';
}) {
  return (
    <Box p={2} display="flex" alignItems="center" justifyContent={justify}>
      <Typography
        variant="subtitle1"
        sx={(theme) => ({
          fontWeight: 'bold',
          color: condition
            ? theme.palette.text.primary
            : theme.palette.grey[500],
        })}>
        {title}
      </Typography>
      {condition ? (
        <CheckCircle
          sx={(theme) => ({
            fontSize: '1.5rem',
            marginLeft: theme.spacing(1),
          })}
          fontSize="inherit"
          color="primary"
        />
      ) : (
        <HighlightOff
          color="disabled"
          sx={(theme) => ({
            fontSize: '1.5rem',
            marginLeft: theme.spacing(1),
          })}
        />
      )}
    </Box>
  );
}
