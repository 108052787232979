import LensIcon from '@material-ui/icons/Lens';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import themev5 from 'src/themev5';

import { convertValue } from '../../utils/number';

export default function PanelTitles({
  series,
  emissionDate,
  cetipCode,
  amountIssued,
  term,
  remuneration,
}: {
  series?: string;
  emissionDate?: string;
  cetipCode?: string;
  amountIssued?: number;
  term?: number;
  remuneration?: number | string;
}) {
  return (
    <Box my={2} mx={1} display="flex" alignItems="center">
      <Box
        position="absolute"
        alignItems="center"
        height={55}
        sx={(theme) => ({
          borderLeft: `6px solid ${theme.palette.secondary.main}`,
        })}
      />
      <Paper
        elevation={6}
        square
        sx={(theme) => ({
          marginLeft: 3,
          padding: theme.spacing(3, 1),
          width: 350,
          [theme.breakpoints.down('xs')]: {
            width: 270,
            minHeight: 230,
          },
        })}>
        <Box p={1}>
          <Grid container>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon
                  style={{
                    fontSize: '0.6rem',
                    color: '#FFCDA0',
                    marginRight: themev5.spacing(1),
                  }}
                  titleAccess="ponto"
                />
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}>
                  Série
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  paddingLeft: theme.spacing(2),
                  [theme.breakpoints.down('xs')]: {
                    paddingLeft: 0,
                  },
                })}>
                {series ?? 'N/D'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon
                  style={{
                    fontSize: '0.6rem',
                    color: '#FFCDA0',
                    marginRight: themev5.spacing(1),
                  }}
                  titleAccess="ponto"
                />
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}>
                  Data de emissão
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  paddingLeft: theme.spacing(2),
                  [theme.breakpoints.down('xs')]: {
                    paddingLeft: 0,
                  },
                })}>
                {emissionDate ?? 'N/D'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={1}>
          <Grid container>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon
                  style={{
                    fontSize: '0.6rem',
                    color: '#FFCDA0',
                    marginRight: themev5.spacing(1),
                  }}
                  titleAccess="ponto"
                />
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}>
                  Código Cetip
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  paddingLeft: theme.spacing(2),
                  [theme.breakpoints.down('xs')]: {
                    paddingLeft: 0,
                  },
                })}>
                {cetipCode ?? 'N/D'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon
                  style={{
                    fontSize: '0.6rem',
                    color: '#FFCDA0',
                    marginRight: themev5.spacing(1),
                  }}
                  titleAccess="ponto"
                />
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}>
                  Montante Emitido
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  paddingLeft: theme.spacing(2),
                  [theme.breakpoints.down('xs')]: {
                    paddingLeft: 0,
                  },
                })}>
                {amountIssued
                  ? `${convertValue(amountIssued, 'million')}`
                  : 'N/D'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box p={1}>
          <Grid container>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon
                  style={{
                    fontSize: '0.6rem',
                    color: '#FFCDA0',
                    marginRight: themev5.spacing(1),
                  }}
                  titleAccess="ponto"
                />
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}>
                  Prazo
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  paddingLeft: theme.spacing(2),
                  [theme.breakpoints.down('xs')]: {
                    paddingLeft: 0,
                  },
                })}>
                {term ?? 'N/D'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <LensIcon
                  style={{
                    fontSize: '0.6rem',
                    color: '#FFCDA0',
                    marginRight: themev5.spacing(1),
                  }}
                  titleAccess="ponto"
                />
                <Typography
                  variant="body2"
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                  })}>
                  Remuneração
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  paddingLeft: theme.spacing(2),
                  [theme.breakpoints.down('xs')]: {
                    paddingLeft: 0,
                  },
                })}>
                {remuneration ?? 'N/D'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}
