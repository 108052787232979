import { Box, Grid, Link } from '@mui/material';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { findInstitutionByCNPJ } from 'src/services/institution';
import { OperationResume } from 'src/types/operations/cr';
import { Media } from 'src/types/strapi';
import { formatCNPJ } from 'src/utils/document';

import CardServiceProvider from '../../../../../components/CardServiceProvider';
import Subtitle from '../../../../../components/Subtitle';
import { useAuthContext } from '../../../../../context/AuthContextProvider';

function CardServiceProviderWrap({
  title,
  name,
  logo,
  cnpj,
}: {
  title: string;
  name?: string;
  logo?: Media;
  cnpj: string;
}) {
  const { data, isLoading, error } = useQuery({
    queryKey: ['institution', cnpj],
    queryFn: () => findInstitutionByCNPJ(formatCNPJ(cnpj)),
  });

  if (isLoading) return null;
  if (error) return null;

  return (
    <>
      {data?.[0]?.slug && data?.[0]?.id ? (
        <Link
          component={RouterLink}
          underline="none"
          to={`/instituicao/${data[0].slug}/${data[0].id}`}>
          <CardServiceProvider
            title={title}
            name={name || ''}
            logo={data?.[0]?.logo}
          />
        </Link>
      ) : (
        <CardServiceProvider title={title} name={name || ''} logo={logo} />
      )}
    </>
  );
}

type OperationCRResumeServiceProviderProps = Pick<
  OperationResume,
  'serviceProviders'
>;

export default function OperationCRResumeServiceProvider({
  serviceProviders,
}: OperationCRResumeServiceProviderProps) {
  const { auth } = useAuthContext();

  const canViewServiceProviders =
    auth?.user?.subscription?.plan?.permissions[
      'operacoes_cr_prestadores_de_servicos'
    ];

  return (
    <Box p={1}>
      <Subtitle title="Prestadores de Serviço" />
      <Box py={4}>
        <Grid container spacing={1}>
          {serviceProviders?.fiduciaryAgents.length > 0 &&
            serviceProviders.fiduciaryAgents.map(
              ({ logo, name, cnpj }) =>
                name !== '' && (
                  <Grid item xs={12} sm={6} md={12} lg={6} key={cnpj}>
                    {cnpj ? (
                      <CardServiceProviderWrap
                        title="Agente Fiduciário"
                        logo={logo}
                        name={name}
                        cnpj={cnpj}
                      />
                    ) : (
                      <CardServiceProvider
                        title="Agente Fiduciário"
                        name={name || ''}
                        logo={logo}
                        alert={!canViewServiceProviders}
                        lock={!canViewServiceProviders}
                      />
                    )}
                  </Grid>
                ),
            )}
          {serviceProviders?.auditors.length > 0 &&
            serviceProviders.auditors.map(
              ({ logo, name, cnpj }) =>
                name && (
                  <Grid item xs={12} sm={6} md={12} lg={6} key={cnpj}>
                    {cnpj ? (
                      <CardServiceProviderWrap
                        title="Auditor"
                        logo={logo}
                        name={name}
                        cnpj={cnpj}
                      />
                    ) : (
                      <CardServiceProvider
                        title="Auditor"
                        alert={!canViewServiceProviders}
                        lock={!canViewServiceProviders}
                        name={name}
                        logo={logo}
                      />
                    )}
                  </Grid>
                ),
            )}
        </Grid>
      </Box>
    </Box>
  );
}
