import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from '@mui/material';
import themev5 from 'src/themev5';
import { Media } from 'src/types/strapi';
import { getSmallestImageUrl } from 'src/utils/image';

import breakDefault from '../../assets/imgs/break-default.svg';

type CardServiceProviderProps = {
  alert?: boolean;
  link?: boolean;
  lock?: boolean;
  logo?: Media;
  name: string;
  title: string;
};

export default function CardServiceProvider({
  alert,
  link,
  lock,
  logo,
  name,
  title,
}: CardServiceProviderProps) {
  return (
    <Card square sx={{ display: 'flex' }}>
      {!logo || lock ? (
        <CardMedia
          sx={{
            width: 85,
            backgroundSize: 'contain',
            marginLeft: themev5.spacing(1),
            '&:hover': {
              backgroundColor: !link
                ? themev5.palette.grey[50]
                : themev5.palette.primary.main,
            },
          }}
          image={breakDefault}
          title="Sem imagem"
        />
      ) : (
        <CardMedia
          sx={{
            width: 85,
            backgroundSize: 'contain',
            marginLeft: themev5.spacing(1),
          }}
          image={getSmallestImageUrl(logo)}
          title={name}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent>
          <Typography
            variant="subtitle1"
            fontWeight={themev5.typography.fontWeightBold}>
            {title}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography
              component="div"
              variant="subtitle2"
              sx={{
                whiteSpace: 'nowrap',
                width: 150,
                ...(lock && {
                  '-webkit-filter': 'blur(5px)',
                }),
              }}>
              <Box textOverflow="ellipsis" overflow="hidden">
                {lock ? 'Nome do gestor' : name}
              </Box>
            </Typography>
            {alert && (
              <Tooltip
                disableFocusListener
                disableTouchListener
                arrow
                title="Acesse a aba de Prestadores de Serviço para visualizar todos os prestadores de serviço da operação"
                placement="bottom">
                <LockOutlinedIcon
                  color="secondary"
                  titleAccess="bloqueado"
                  style={{ marginLeft: themev5.spacing(1), fontSize: '1.2rem' }}
                />
              </Tooltip>
            )}
          </Box>
        </CardContent>
      </div>
    </Card>
  );
}
