import { Media } from 'src/types/strapi';

export function getLargestImageUrl(image?: Media | null | undefined) {
  if (!image) {
    return '';
  }

  if (image.formats) {
    const { small, medium, large } = image.formats;
    return large?.url || medium?.url || small?.url || image.url;
  }

  return image.url;
}

export function getSmallestImageUrl(image?: Media | null | undefined) {
  if (!image) {
    return '';
  }

  if (image.formats) {
    const { thumbnail } = image.formats;
    return thumbnail?.url || image.url;
  }

  return image.url;
}

export const calculateAspectRatioFit = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number,
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
};
