import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';

import { convertNumber, convertValue, toCurrency } from '../../utils/number';
import DateChip from '../DateChip';

export default function PaperPanelInfo({
  number,
  numeric,
  million,
  title,
  value,
  first,
  operationTicker,
  operationB3Ticker,
  operationCodeTipif,
  operationCodeIsIn,
  operationLastDistributed,
  date,
  dateTitle,
  lock,
  loading,
}: {
  number?: boolean;
  numeric?: boolean;
  million?: boolean;
  title: string;
  value?: any;
  first?: boolean;
  operationTicker?: string;
  operationB3Ticker?: string;
  operationCodeTipif?: string;
  operationCodeIsIn?: string;
  operationLastDistributed?: string;
  date?: string;
  dateTitle?: string;
  lock?: boolean;
  loading?: boolean;
}) {
  return (
    <Box display="flex" alignItems="center">
      <Box
        position="absolute"
        alignItems="center"
        zIndex="mobileStepper"
        height={55}
        sx={(theme) => ({
          borderLeft: `6px solid ${theme.palette.primary.main}`,
        })}
      />
      <Grid container>
        <Grid item xs={12}>
          <Paper
            square
            sx={(theme) => ({
              padding: theme.spacing(3, 2),
              minHeight: 150,
              alignItems: 'center',
              display: 'flex',
              position: 'relative',
              ...(first && {
                backgroundColor: '#fafafa',
              }),
            })}>
            <Box width="100%">
              <Typography
                variant="subtitle1"
                sx={(theme) => ({
                  fontWeight: theme.typography.fontWeightBold,
                })}>
                {title}
              </Typography>
              {loading ? (
                <Skeleton variant="text" animation="wave" />
              ) : (
                <>
                  {numeric && !lock ? (
                    <Typography variant="subtitle2">
                      {value && !isNaN(value) ? (
                        <>
                          {million
                            ? `${convertValue(value)}`
                            : toCurrency(value)}
                        </>
                      ) : (
                        'N/D'
                      )}
                    </Typography>
                  ) : number ? (
                    <Typography variant="subtitle2">
                      {value && !isNaN(value) ? (
                        <>{convertNumber(value)}</>
                      ) : (
                        'N/D'
                      )}
                    </Typography>
                  ) : operationTicker ? (
                    <Typography variant="subtitle2">
                      {operationTicker}
                    </Typography>
                  ) : operationB3Ticker ? (
                    <Typography variant="subtitle2">{`B3: ${operationB3Ticker}`}</Typography>
                  ) : operationCodeTipif ? (
                    <Typography variant="subtitle2">
                      {`IF: ${operationCodeTipif}`}
                    </Typography>
                  ) : operationCodeIsIn ? (
                    <Typography variant="subtitle2">{`ISIN: ${operationCodeIsIn}`}</Typography>
                  ) : operationLastDistributed ? (
                    <Typography variant="subtitle2">
                      {operationLastDistributed
                        ? `${toCurrency(operationLastDistributed)} / cota`
                        : 'N/D'}
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle2"
                      sx={(theme) => ({
                        ...(lock && {
                          '-webkit-filter': 'blur(5px)',
                        }),
                      })}>
                      {value ? value : 'N/D'}
                    </Typography>
                  )}
                </>
              )}
            </Box>
            {date && !loading && (
              <Box
                sx={{
                  alignSelf: 'end',
                  paddingBottom: '1rem',
                  width: '50%',
                  display: 'flex',
                }}>
                <DateChip title={dateTitle} date={date} loading={loading} />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

PaperPanelInfo.defaultProps = {
  million: true,
};
