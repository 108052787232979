/* eslint-disable no-console */
import { useEffect, useState } from 'react';

import { Box, CircularProgress, Paper } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { getOperationGeneralTabData } from 'src/services/operation/operationCr';
import { GeneralData } from 'src/types/operations/cr';

import OperationCRAsset from './Asset';
import OperationCRGeneralData from './General';
import OperationCRLiability from './Liability';
import OperationCRResume from './Resume';
import OperationCRServiceProvider from './ServiceProvider';
import { If } from '../../../components/If';
import MetaTags from '../../../components/Seo/MetaTags';
import Subheader from '../../../components/Subheader';
import { TabSection } from '../../../components/TabSection';

export default function OperationCR() {
  const { market, slug, hashId, tab } = useParams<{
    market: string;
    slug: string;
    hashId: string;
    tab: string;
  }>();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(
    parseInt(window.localStorage.getItem('operationTabIndex') || '0') ||
      'resumo',
  );
  const tabs = [
    { label: 'Resumo', value: 'resumo' },
    { label: 'Dados Gerais', value: 'dados' },
    { label: 'Prestadores de Serviço', value: 'prestadores' },
    { label: 'Ativo', value: 'ativo' },
    { label: 'Passivo', value: 'passivo' },
  ];

  const [data, setData] = useState<GeneralData>({} as GeneralData);
  const { isLoading, error } = useQuery({
    queryKey: ['operationGeneral'],
    queryFn: () => getOperationGeneralTabData(hashId),
    onSuccess: (response) => {
      setData(response);
    },
  });

  const handleTabChange = (tabIndex: string) => {
    setSelectedTab(tabIndex);
    if (tabIndex === 'resumo') {
      history.push(`/operacao/${market}/${slug}/${hashId}`);
    } else {
      history.push(`/operacao/${market}/${slug}/${hashId}/${tabIndex}`);
    }
  };

  useEffect(() => {
    setSelectedTab(tab ?? 'resumo');
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      'operationTabIndex',
      JSON.stringify(selectedTab),
    );
    return function clean() {
      localStorage.removeItem('operationTabIndex');
    };
  }, [selectedTab]);

  if (isLoading) return <CircularProgress color="secondary" />;
  if (error) return null;

  const operantionName = data?.operationName;

  return (
    <>
      <MetaTags
        title={`${operantionName} - CR`}
        description={`Encontre as principais informações sobre ${operantionName}`}
      />
      <Subheader
        title={operantionName}
        market={market?.toUpperCase()}
        breadcrumbs1="CR"
        breadcrumbs2={`${operantionName} ${market?.toUpperCase()}`}
        favoriteType="operation"
        disableFavorite={true}
      />
      <Box py={2}>
        <TabSection
          tabList={tabs}
          value={selectedTab}
          setValue={handleTabChange}
          isProfile={false}>
          <Paper square>
            <If condition={selectedTab === 'resumo'}>
              <OperationCRResume hashId={hashId} />
            </If>
            <If condition={selectedTab === 'dados'}>
              <OperationCRGeneralData hashId={hashId} />
            </If>
            <If condition={selectedTab === 'prestadores'}>
              <OperationCRServiceProvider hashId={hashId} />
            </If>
            <If condition={selectedTab === 'ativo'}>
              <OperationCRAsset hashId={hashId} />
            </If>
            <If condition={selectedTab === 'passivo'}>
              <OperationCRLiability hash={hashId} />
            </If>
          </Paper>
        </TabSection>
      </Box>
    </>
  );
}
