import { useState } from 'react';

import {
  Collapse,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {
  AccountBalance,
  Assessment,
  Assignment,
  ChevronLeft,
  ChevronRight,
  Cloud,
  EmojiPeople,
  EventAvailable,
  ExpandLess,
  ExpandMore,
  Home,
  LiveHelp,
  MenuBook,
  OndemandVideo,
  People,
  QueryStats,
  Star,
  Store,
  Timeline,
} from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';

import CalculateIcon from '../../pages/Calculator/icons/Calculate';
import { CustomTooltip } from '../CustomTooltip';
import ArticleIcon from '../Icons/ArticleIcon';
import RadarIcon from '../Icons/RadarIcon';
import { If } from '../If';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'visible',
    position: 'static',
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
    },
    '& .MuiDrawer-paper': {
      height: (props) => (props.isDemo ? 'calc(100% - 57px)' : '100%'),
    },
  },
  drawerOpen: {
    marginTop: (props) => (props.isDemo ? '65px' : '0px'),
    width: drawerWidth,
    overflow: 'visible',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
    },
  },
  drawerClose: {
    marginTop: (props) => (props.isDemo ? '65px' : '0px'),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'visible',
    width: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  button: {
    '& > button': {
      padding: '5px',
      '& > span': {
        '& > svg': {
          fontSize: '1rem',
        },
      },
    },
  },
  buttonOpen: {
    zIndex: 1000,
    position: 'relative',
    left: '225px',
    top: '70px',
    margin: theme.spacing(3, 0),
    padding: theme.spacing(0, 1),
    overflow: 'visible',
    '& > button': {
      border: '1px solid #797979',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
        border: '1px solid #E48125',
        color: theme.palette.secondary.main,
      },
    },
  },
  buttonClose: {
    zIndex: 1000,
    position: 'relative',
    left: '50px',
    top: '70px',
    margin: theme.spacing(3, 0),
    padding: theme.spacing(0, 1),
    overflow: 'visible',
    '& > button': {
      border: '1px solid #797979',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
        border: '1px solid #E48125',
        color: theme.palette.secondary.main,
      },
    },
  },
  list: {
    direction: 'rtl',
    overflow: 'auto',
    '&::-webkit-scrollbar': { height: 10, width: 8, WebkitAppearance: 'none' },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: theme.palette.grey['500'],
    },
    '& a': {
      direction: 'ltr',
    },
    '& div': {
      direction: 'ltr',
    },
    '& svg': {
      color: '#4F4F4F',
    },
    '& span': {
      fontWeight: 600,
      color: '#4F4F4F',
    },
  },
  subList: {
    marginLeft: '32px',
    '& a': {
      minHeight: '40px',
      padding: '0px 16px',
    },
    '& span': {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: '0.875rem',
      color: '#4F4F4F',
    },
  },
}));

export default function SideMenu({ open, setOpen, toggleMenu, isDemo }) {
  const classes = useStyles({ isDemo });
  const { pathname } = useLocation();
  const [showTooltip, setShowTooltip] = useState(false);
  const [rankingOpen, setRankingOpen] = useState(false);
  const [articleOpen, setArticleOpen] = useState(false);
  const [operationOpen, setOperationOpen] = useState(false);
  const isLargeScreen = useMediaQuery('(min-width: 1640px)');

  const handleDrawerToggle = () => {
    toggleMenu();
    setShowTooltip(false);
    setRankingOpen(false);
    setArticleOpen(false);
    setOperationOpen(false);
  };

  const closeMenu = () => {
    if (isLargeScreen) return;
    setOpen(false);
    setShowTooltip(false);
    setRankingOpen(false);
    setArticleOpen(false);
    setOperationOpen(false);
  };

  const handleRankingClick = () => {
    setOpen(true);
    setRankingOpen((prevState) => !prevState);
  };

  const handleArticleClick = () => {
    setOpen(true);
    setArticleOpen((prevState) => !prevState);
  };

  const handleOperationClick = () => {
    setOpen(true);
    setOperationOpen((prevState) => !prevState);
  };

  const inInYearbookPage = pathname.startsWith('/anuarios/2024');

  const MenuList = (
    <List className={classes.list}>
      <ListItem button component={Link} onClick={closeMenu} to="/">
        <CustomTooltip placement="right" title={open ? '' : 'Início'}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Início" />
        </If>
      </ListItem>
      <ListItem button component={Link} onClick={closeMenu} to="/favoritos">
        <CustomTooltip placement="right" title={open ? '' : 'Favoritos'}>
          <ListItemIcon>
            <Star />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Favoritos" />
        </If>
      </ListItem>
      <ListItem button component={Link} onClick={closeMenu} to="/radar">
        <CustomTooltip placement="right" title={open ? '' : 'Radar'}>
          <ListItemIcon>
            <RadarIcon style={{ marginTop: '8px' }} />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Radar" />
        </If>
      </ListItem>
      <ListItem button onClick={handleRankingClick}>
        <CustomTooltip placement="right" title={open ? '' : 'Rankings'}>
          <ListItemIcon>
            <Timeline />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Rankings" />
          {rankingOpen ? <ExpandLess /> : <ExpandMore />}
        </If>
      </ListItem>
      <Collapse in={rankingOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.subList}>
          <ListItem
            button
            component={Link}
            onClick={closeMenu}
            to="/rankings?market=FIDC">
            <ListItemText primary="FIDC" />
          </ListItem>
          <ListItem
            button
            component={Link}
            onClick={closeMenu}
            to="/rankings?market=CRI">
            <ListItemText primary="CRI" />
          </ListItem>
          <ListItem
            button
            component={Link}
            onClick={closeMenu}
            to="/rankings?market=CRA">
            <ListItemText primary="CRA" />
          </ListItem>
          <ListItem
            button
            component={Link}
            onClick={closeMenu}
            to="/rankings?market=FII">
            <ListItemText primary="FII" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button component={Link} onClick={closeMenu} to="/calculadora">
        <CustomTooltip placement="right" title={open ? '' : 'Calculadora'}>
          <ListItemIcon>
            <CalculateIcon />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Calculadora" />
        </If>
      </ListItem>
      <ListItem
        button
        component={Link}
        onClick={closeMenu}
        to="/rentabilidades">
        <CustomTooltip placement="right" title={open ? '' : 'Rentabilidades'}>
          <ListItemIcon>
            <QueryStats />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Rentabilidades" />
        </If>
      </ListItem>
      <ListItem button onClick={handleOperationClick}>
        <CustomTooltip placement="right" title={open ? '' : 'Operações'}>
          <ListItemIcon>
            <Assessment />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Operações" />
          {operationOpen ? <ExpandLess /> : <ExpandMore />}
        </If>
      </ListItem>
      <Collapse in={operationOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.subList}>
          <ListItem
            button
            component={Link}
            onClick={closeMenu}
            to="/operacoes?market=FIDC&mode=basic">
            <ListItemText primary="FIDC" />
          </ListItem>
          <ListItem
            button
            component={Link}
            onClick={closeMenu}
            to="/operacoes?market=CRI&mode=basic">
            <ListItemText primary="CRI" />
          </ListItem>
          <ListItem
            button
            component={Link}
            onClick={closeMenu}
            to="/operacoes?market=CRA&mode=basic">
            <ListItemText primary="CRA" />
          </ListItem>
          <ListItem
            button
            component={Link}
            onClick={closeMenu}
            to="/operacoes?market=CR&mode=basic">
            <ListItemText primary="CR" />
          </ListItem>
          <ListItem
            button
            component={Link}
            onClick={closeMenu}
            to="/operacoes?market=FII&mode=basic">
            <ListItemText primary="FII" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button component={Link} onClick={closeMenu} to="/instituicoes">
        <CustomTooltip placement="right" title={open ? '' : 'Instituições'}>
          <ListItemIcon>
            <AccountBalance />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Instituições" />
        </If>
      </ListItem>
      <ListItem button component={Link} onClick={closeMenu} to="/profissionais">
        <CustomTooltip placement="right" title={open ? '' : 'Profissionais'}>
          <ListItemIcon>
            <People />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Profissionais" />
        </If>
      </ListItem>
      <ListItem button onClick={handleArticleClick}>
        <CustomTooltip placement="right" title={open ? '' : 'Artigos'}>
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Artigos" />
          {articleOpen ? <ExpandLess /> : <ExpandMore />}
        </If>
      </ListItem>
      <Collapse in={articleOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.subList}>
          <ListItem button component={Link} onClick={closeMenu} to="/artigos">
            <ListItemText primary="Todos" />
          </ListItem>
          <ListItem
            button
            component={Link}
            onClick={closeMenu}
            to="/publicar/artigos">
            <ListItemText primary="Publique na Uqbar" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem button component={Link} onClick={closeMenu} to="/videos">
        <CustomTooltip
          placement="right"
          title={open ? '' : 'Galeria de Vídeos'}>
          <ListItemIcon>
            <OndemandVideo />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Galeria de Vídeos" />
        </If>
      </ListItem>
      <ListItem button component={Link} onClick={closeMenu} to="/documentos">
        <CustomTooltip
          placement="right"
          title={open ? '' : 'Central de documentos'}>
          <ListItemIcon>
            <Assignment />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Central de documentos" />
        </If>
      </ListItem>
      <ListItem button component={Link} onClick={closeMenu} to="/treinamentos">
        <CustomTooltip placement="right" title={open ? '' : 'Treinamentos'}>
          <ListItemIcon>
            <EmojiPeople />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Treinamentos" />
        </If>
      </ListItem>
      <ListItem button component={Link} onClick={closeMenu} to="/eventos">
        <CustomTooltip placement="right" title={open ? '' : 'Eventos'}>
          <ListItemIcon>
            <EventAvailable />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Eventos" />
        </If>
      </ListItem>
      <ListItem button component={Link} onClick={closeMenu} to="/marketplace">
        <CustomTooltip placement="right" title={open ? '' : 'Marketplace'}>
          <ListItemIcon>
            <Store />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Marketplace" />
        </If>
      </ListItem>
      <ListItem button component={Link} onClick={closeMenu} to="/anuarios">
        <CustomTooltip placement="right" title={open ? '' : 'Anuários'}>
          <ListItemIcon>
            <MenuBook />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="Anuários" />
        </If>
      </ListItem>
      <ListItem button component={Link} onClick={closeMenu} to="/apis">
        <CustomTooltip placement="right" title={open ? '' : 'APIs'}>
          <ListItemIcon>
            <Cloud />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="APIs" />
        </If>
      </ListItem>
      <ListItem button component={Link} onClick={closeMenu} to="/faq">
        <CustomTooltip placement="right" title={open ? '' : 'FAQ'}>
          <ListItemIcon>
            <LiveHelp />
          </ListItemIcon>
        </CustomTooltip>
        <If condition={open}>
          <ListItemText primary="FAQ" />
        </If>
      </ListItem>
    </List>
  );

  return (
    <nav>
      <Hidden mdDown>
        <If condition={!inInYearbookPage}>
          <Drawer
            variant="permanent"
            open={true}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}>
            <div
              className={clsx(classes.button, {
                [classes.buttonOpen]: open,
                [classes.buttonClose]: !open,
              })}>
              <CustomTooltip
                open={showTooltip}
                disableHoverListener
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                placement="right"
                title={open ? 'Fechar' : 'Abrir'}>
                <IconButton onClick={handleDrawerToggle}>
                  {open ? <ChevronLeft /> : <ChevronRight />}
                </IconButton>
              </CustomTooltip>
            </div>
            {MenuList}
          </Drawer>
        </If>
      </Hidden>
      <Hidden lgUp={!inInYearbookPage}>
        <Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}>
          {MenuList}
        </Drawer>
      </Hidden>
    </nav>
  );
}
