import { useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useQuery } from 'react-query';
import { getOperationLiabilityTabData } from 'src/services/operation/operationCr';
import {
  OperationLiabilityEmission,
  OperationLiabilityTitle,
} from 'src/types/operations/cr';

import { OperationCRLiabilityData } from './LiabilityData';
import { OperationCRLiabilityTitle } from './LiabilityTitle';
import { PanelLogin } from '../../../../components/PanelLogin';
import { useAuthContext } from '../../../../context/AuthContextProvider';

export default function OperationCRLiability({ hash }: { hash: string }) {
  const { auth } = useAuthContext();
  const [liabilities, setLiabilities] = useState<OperationLiabilityTitle[]>([]);
  const [emissions, setEmissions] = useState<OperationLiabilityEmission[]>([]);
  const { isLoading } = useQuery({
    queryKey: ['operation-cr-liability'],
    queryFn: () => getOperationLiabilityTabData(hash),
    onSuccess(data) {
      setLiabilities(data.titles);
      setEmissions(data.emissions);
    },
  });

  return (
    <Box p={2}>
      {!auth?.user?.subscription?.plan?.permissions['operacoes_cr_passivo'] ? (
        <Box p={2}>
          <PanelLogin />
        </Box>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <OperationCRLiabilityData
              data={liabilities}
              emission={emissions}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item xs={12}>
            <OperationCRLiabilityTitle
              data={liabilities}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
